/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Footer.scss";
import facebook from "../../assets/SocialMedia/facebook.png";
import insta from "../../assets/SocialMedia/instagram.png";
import linkidIn from "../../assets/SocialMedia/linkedin.png";
import youtube from "../../assets/SocialMedia/youtube.png";
import twitter from "../../assets/SocialMedia/twitter.png";
import { AppFooter } from "../../StyleConfig/Common/CommonStyles";

function Footer() {
  return (
    <div style={{ backgroundColor: "#FFF", position: "relative", zIndex: 1 }}>
      <AppFooter>
        <div className="container">
          <div className="col-md-12 col-sm-12">
            <div className="row justify-content-center">
              <div className="footer_heading">© Skill’Ohana, Inc. 2022</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 justify-center footer_top_middle">
            <div className="row justify-content-center">
              <div className="footer_links">
                <div className="footer_link_box">Help</div>
                <div className="footer_link_box">
                  <a
                    href="https://skillohana.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </div>
                <div className="footer_link_box">Terms</div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 footer_top_bottom">
            <div className="row justify-content-center">
              <div className="footer_social_media_box">
                <a
                  className="sm_icon"
                  href="https://facebook.com/SkillOhana/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} />
                </a>

                <a
                  className="sm_icon"
                  href="https://www.instagram.com/skillohana/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={insta} />
                </a>
                <a
                  className="sm_icon"
                  href="https://www.linkedin.com/company/skill-ohana"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkidIn} />
                </a>
                <a
                  className="sm_icon"
                  href="https://twitter.com/skillohana"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} />
                </a>
              </div>
            </div>
          </div>
          <span style={{ display: "none" }}>Version 0.0.5</span>
          <div style={{ height: "40px" }} />
        </div>
      </AppFooter>
    </div>
  );
}

export default Footer;
