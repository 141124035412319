import React, { useState } from "react";
import DragDrop from "../../../components/DragDrop/DragDrop";
import "./Section.scss";

function UploadProfiles({
  data,
  teachers,
  students,
  learntoteachonline,
  handleBoolean,
  handleDrop,
  profilepic,
  handleRadioBoolean,
  profilepicname,
}) {
  const [checks, setChecks] = useState({
    teacher: false,
    student: false,
    teachOnline: false,
  });

  // const { teacher, student, teachOnline } = checks;

  return (
    <div className="row justify-center">
      <div className="col-md-8">
        <h5 className="form__heading">Upload Profile Picture</h5>
        <div style={{ height: "20px" }} />
        <DragDrop
          styles={"dragdrop__container"}
          handleDrop={handleDrop}
          data={data}
          profilepic={profilepic}
          imagename={profilepicname}
        />
      </div>
    </div>
  );
}

export default UploadProfiles;
