import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import RateReviewIcon from "@material-ui/icons/RateReview";
import {
  Cards,
  CourseDescription,
  CourseGridImage,
  CourseGridSubtitle,
  CourseGridTitle,
  LoaderContainer,
} from "../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import "./CoursesCard.scss";
import { useDataLayerValue } from "../../context/DataLayer";

function CoursesCard({
  courses,
  location,
  category,
  getcoursebycategory,
  routePath,
  handleCourseDelete,
  checkLoading,
}) {
  const [{}, dispatch] = useDataLayerValue();
  useEffect(() => {
    console.log("Check course available", courses.length);
  }, []);

  return (
    <div>
      <div style={{ height: "20px" }} />
      {courses.map((course, index) => {
        return (
          <>
            <Cards key={index + "courses"} style={{ position: "relative" }}>
              <div key={index + "course"} className="row">
                <div className="col-md-2">
                  <Link
                    to={{
                      pathname: `/courses/mycourses/coursedetail/${parseInt(
                        course.id
                      )}`,
                      state: course,
                    }}
                  >
                    <div className="image__container">
                      <CourseGridImage
                        src={`${IMAGECONFIG.IMAGECONFIG.MEDIUM}${course.media_id}`}
                        alt={course.name}
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-md-10">
                  <div className="d-flex justify-between">
                    <div>
                      <Link
                        to={{
                          pathname: `/courses/mycourses/coursedetail/${parseInt(
                            course.id
                          )}`,
                          state: course,
                        }}
                      >
                        <CourseGridTitle>{course.name}</CourseGridTitle>
                      </Link>
                    </div>
                    {category !== "Explorecourses" && (
                      <div className="d-flex around" style={{ width: "20%" }}>
                        <div>
                          <Link
                            to={{
                              pathname: `/courses/mycourses/editcourse/${parseInt(
                                course.id
                              )}`,
                            }}
                          >
                            <EditIcon style={{ color: "#325288" }} />
                          </Link>
                        </div>
                        <div
                          onClick={() => {
                            handleCourseDelete(course.id);
                          }}
                        >
                          <DeleteIcon
                            style={{ color: "#FF3A66", cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <Link
                    to={{
                      pathname: `/courses/mycourses/coursedetail/${parseInt(
                        course.id
                      )}`,
                      state: course,
                    }}
                  >
                    <div className="d-flex">
                      <CourseGridSubtitle>{course.info}</CourseGridSubtitle>
                    </div>
                  </Link>
                  <div>
                    <CourseDescription>
                      - {course.author_name}
                    </CourseDescription>
                  </div>

                  <div className="d-flex justify-between">
                    <div>
                      <div className="tags__body">
                        <Link
                          to={`/courses/${routePath}/category/${course.cat_id}`}
                          // .replace(/\s+/g, "-")
                          // .toLowerCase()}`}
                        >
                          <span className="tags__title">{course.cat_id}</span>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Link
                          to={{
                            pathname: `/courses/mycourses/coursedetail/${parseInt(
                              course.id
                            )}`,
                            state: course,
                          }}
                        >
                          <TimelapseIcon style={{ color: "#1EA69A" }} />
                          <span className="count__title">
                            {course.courses_sections_ctn} Lessons
                          </span>
                        </Link>
                        <Link
                          to={{
                            pathname: `/courses/mycourses/coursedetail/${parseInt(
                              course.id
                            )}`,
                            state: course,
                          }}
                          onClick={() => {
                            dispatch({
                              segmentTabValue: 2,
                              type: "SET__SEGMENTTAB__VALUE",
                            });
                          }}
                        >
                          <RateReviewIcon style={{ color: "#845460" }} />

                          <span className="count__title">
                            {Math.trunc(course.course_rate)} Reviews
                          </span>
                        </Link>

                        <VisibilityIcon style={{ color: "#18A0FB" }} />
                        <span className="count__title">
                          {course.courses_view_ctn} Views
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Cards>
            <div style={{ height: "10px" }} />
          </>
        );
      })}
      {courses.length === 0 && (
        <LoaderContainer style={{ height: "60vh" }}>
          <h4>No courses available</h4>
        </LoaderContainer>
      )}
    </div>
  );
}

export default CoursesCard;
