import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import Responsive from "../../ResponsiveLayouts/Responsive";
import LandingPage from "../LandingPage/LandingPage";
import HomePageCourses from "./HomePageCourses/HomePageCourses";
import HomePageReviews from "./HomePageReviews/HomePageReviews";
import NewsFeeds from "./NewsFeeds/NewsFeeds";
import DanceImage from "../../assets/BackgroundImages/dance.jpeg";
import "./HomePage.scss";
import Footer from "../../components/Footer/Footer";
import { AppContainer } from "../../StyleConfig/Common/CommonStyles";
import {
  getnewsfeeds,
  getpublishcourses,
  getrecentcourses,
  getrecommendedcourses,
  getreviewshomepage,
} from "../../Core/coreapicalls";
import { ShimmerEffect } from "../../StyleConfig/Common/Shimmer";

function HomePage({ isTabletOrMobile, isBigScreen, isDesktopOrLaptop }) {
  const [recomendcourse, setRecomendcourse] = useState([]);
  const [recentcourses, setRecentcourses] = useState([]);
  const [publishcourses, setPublishcourse] = useState([]);
  const [newsfeedshome, setNewsfeedshome] = useState([]);
  const [reviewshome, setReviewshome] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState({
    homepageNewsLoading: false,
    homepagecourseloading: false,
    reviewsloading: false,
  });

  /**
   * Recommended Course Api call (Home Page)
   */

  const recommendcourseapi = () => {
    setLoading({ ...loading, homepagecourseloading: true });
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getrecommendedcourses(reqBody)
      .then((data) => {
        setLoading({ ...loading, homepagecourseloading: false });
        if (data.status === true) {
          setRecomendcourse(data.data);
        }
      })
      .catch((error) => {
        setLoading({ ...loading, recommendloading: false });

        throw error;
      });
  };

  /**
   * Recent Course Api call (Home Page)
   */

  const recentcourseapi = () => {
    setLoading({ ...loading, homepagecourseloading: true });

    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getrecentcourses(reqBody)
      .then((data) => {
        setLoading({ ...loading, homepagecourseloading: false });

        if (data.status === true) {
          setRecentcourses(data.data);
        }
      })
      .catch((error) => {
        setLoading({ ...loading, recentloading: false });

        throw error;
      });
  };

  /**
   * Published Course Api call (Home Page)
   */

  const publishcourseapi = () => {
    setLoading({ ...loading, homepagecourseloading: true });

    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getpublishcourses(reqBody)
      .then((data) => {
        setLoading({ ...loading, homepagecourseloading: false });
        if (data.status === true) {
          setPublishcourse(data.data);
        }
      })
      .catch((error) => {
        setLoading({ ...loading, publishloading: false });

        throw error;
      });
  };
  /**
   * News Feed Api call (Home Page)
   */

  const newsfeedsapi = () => {
    console.log("Check Page Number Value", pageNumber);
    setLoading({ ...loading, homepageNewsLoading: true });
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      page: pageNumber,
      size: 5,
    };
    getnewsfeeds(reqBody)
      .then((data) => {
        console.log("Check Response for news feeds", data);
        setLoading({ ...loading, homepageNewsLoading: false });
        if (data.status === true) {
          data.data.map((data) => {
            setNewsfeedshome((prevData) => [...prevData, data]);
          });
        }
      })
      .catch((error) => {
        setLoading({ ...loading, homepageNewsLoading: false });

        throw error;
      });
  };

  /**
   * Reviews Api call (Home Page)
   */

  const reviewsapi = () => {
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getreviewshomepage(reqBody)
      .then((data) => {
        if (data.status === true) {
          setReviewshome(data.data);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    recommendcourseapi();
    recentcourseapi();
    publishcourseapi();
    reviewsapi();
  }, []);

  useEffect(() => {
    newsfeedsapi();
  }, [pageNumber]);

  useEffect(() => {
    let a = document.getElementById("newsfeedinner");
    a.addEventListener("scroll", (e) => {
      if (a.scrollTop === a.scrollHeight - a.offsetHeight) {
        console.log(a.scrollTop === a.scrollHeight - a.offsetHeight);
        setPageNumber((pageNumber) => pageNumber + 1);
      }
    });
  }, []);

  return (
    <AppContainer>
      <div className="container home__container">
        <div className="row">
          <div className="col-md-3">
            <HomePageCourses
              course={recomendcourse}
              courseCategory={"Recommended Courses"}
              courseLoading={loading.homepagecourseloading}
            />
            <HomePageCourses
              course={recentcourses}
              courseCategory={"Recent Courses"}
              courseLoading={loading.homepagecourseloading}
            />
          </div>

          <div className="col-md-6">
            <NewsFeeds
              news={newsfeedshome}
              newsLoading={loading.homepageNewsLoading}
            />
          </div>

          <div className="col-md-3">
            <HomePageCourses
              course={publishcourses}
              courseCategory={"Publish Courses"}
              courseLoading={loading.homepagecourseloading}
            />
            <HomePageReviews
              userReviews={reviewshome}
              reviewTitle={"Reviews"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </AppContainer>
  );
}

export default Responsive(HomePage);
