// Validators using Regular Expression

// 1. Email Validator

export const EmailValidators = (value) => {
  // Email Regex
  console.log("check value in email regex", value);
  let rjx = /^[^@]+@[^@]+\.[^@\.]{2,}$/g;
  let isValid = rjx.test(value);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

// 2. Password Validator

export const PasswordValidator = (value) => {
  // Password Regex -> Contain 8 characters, one capital letter, one small letter, one special character
  let rjx = /.{8,}$/g;
  let isValid = rjx.test(value);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

// 3. Name Validator

export const NameValidator = (value) => {
  // Name Regex
  let rjx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/gm;
  let isValid = rjx.test(value);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

// 4. Address Validator

export const AddressValidator = (value) => {
  // Address Regex
  let rjx = /^[0-9]+$/;
  let isValid = rjx.test(value);
  if (isValid) {
    return false;
  } else {
    return true;
  }
};

// 5. Phone Validator

export const PhoneValidators = (value) => {
  // Phone Regex
  // let rjx = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;
  // let rjx = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
  let rjx = /.{8,}$/g;
  let isValid = rjx.test(value);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

// 6. Date of birth validators

export const DobValidators = (value) => {
  let rjx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
  let isValid = rjx.test(value);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};
