import React, { useState } from "react";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import IMAGECONFIG from "../StyleConfig/MediaImage";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";

import "./GridHorizontal.scss";
import { LoaderContainer } from "../StyleConfig/Common/CommonStyles";
import { FadeLoader } from "react-spinners";
import Theme from "../StyleConfig/Theme";
import { Link } from "react-router-dom";

function GridListHorizontal({ data, loading }) {
  return (
    <GridHorizontal>
      <h3>Upcoming learning</h3>
      <div style={{ height: "20px" }} />
      <ScrollContainer horizontal={true} className="container d-flex">
        {loading === false &&
          data &&
          data.map((value, index) => {
            return (
              <div key={index + value.name}>
                <Link
                  to={{
                    pathname: `/courses/mycourses/coursedetail/${parseInt(
                      value.id
                    )}`,
                    // state: value,
                  }}
                >
                  <div className="card card__background">
                    <img
                      src={IMAGECONFIG.IMAGECONFIG.BANNER + value.media_id}
                      className="card-img-top borderradius__10px"
                      alt={value.name}
                    />
                    <div style={{ height: "10px" }} />
                    <h6>{value.name}</h6>
                    <div className="flex-row justify-between mt-2">
                      <span>
                        <TimelapseIcon style={{ color: "#1EA69A" }} size={24} />
                        <span className="ms-2">
                          {value.courses_sections_ctn} lesson
                        </span>
                      </span>
                      <span>- {value.author_name}</span>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </ScrollContainer>
      {loading && (
        <LoaderContainer
          style={{
            height: "auto",
          }}
        >
          <FadeLoader
            color={Theme.LIGHT.INNERLOADER}
            loading={true}
            size={30}
          />
        </LoaderContainer>
      )}
    </GridHorizontal>
  );
}

export default GridListHorizontal;

const GridHorizontal = styled.div`
  // overflow: hidden;
  position: relative;
  padding: 30px;
  background-color: #f6f8fa;
`;

const ScrollMenu = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: auto;
`;
