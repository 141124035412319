import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";
import styled from "styled-components";

function Loader(WrappedComponent) {
  function HOC(props) {
    const [loading, setLoading] = useState(false);

    return (
      <div style={{ zIndex: "10" }}>
        {loading && (
          <LoaderContainer>
            <BeatLoader color={Theme.LIGHT.LOADER} loading={true} size={50} />
          </LoaderContainer>
        )}
        <WrappedComponent {...props} setLoading={setLoading} />
      </div>
    );
  }
  return HOC;
}

export default Loader;

const LoaderContainer = styled.div`
  margin-top: -10px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: rgba(69, 70, 70, 0.4);
`;
