import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import "./Chips.scss";
import { useDataLayerValue, InterestContext } from "../../context/DataLayer";
import Theme from "../../StyleConfig/Theme";

function Chips({ chipData }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
      boxShadow: "none",
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: "rgba(24, 160, 251, 0.28)",
      color: "#18A0FB",
      borderRadius: "5px",
    },
  }));
  const [interest, setInterest] = useContext(InterestContext);
  const classes = useStyles();

  const handleClick = (data, index) => {
    console.info("You clicked the Chip.", data);

    setInterest((prevState) => [...interest, data.name]);
  };
  return (
    <>
      {chipData.map((data, index) => {
        // console.log("Check individual chip data", data);
        return (
          <Chip
            key={data.id + "Chips"}
            label={data.name}
            onClick={() => handleClick(data, index)}
            className={classes.chip}
          />
        );
      })}
    </>
  );
}

export default Chips;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow: "none",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: Theme.LIGHT.CHIPONE,
    color: Theme.LIGHT.CHIPONETEXT,
    borderRadius: "5px",
  },
  chipsTwo: {
    margin: theme.spacing(0.5),
    backgroundColor: Theme.LIGHT.CHIPTWO,
    color: Theme.LIGHT.CHIPTWOTEXT,
    borderRadius: "5px",
  },
}));

export const RemovableChips = ({ data }) => {
  const classes = useStyles();

  const [interest, setInterest] = useContext(InterestContext);

  const handleDelete = (chipToDelete) => () => {
    console.log("Check Chip to delete", chipToDelete, chipToDelete);
    setInterest((chips) => chips.filter((chip) => chip !== chipToDelete));
  };
  return (
    <Paper component="ul" className={classes.root}>
      {data.map((data, index) => {
        console.log("Check Removable chip data:", data);
        let newIndex = index + 1;
        let icon;

        return (
          <li key={data.id + "RemoveableChips"}>
            <Chip
              icon={icon}
              label={data}
              onDelete={
                data.label === "React" ? handleDelete(data) : handleDelete(data)
              }
              className={newIndex % 2 !== 0 ? classes.chip : classes.chipsTwo}
            />
          </li>
        );
      })}
    </Paper>
  );
};

/**
 * (Chips for courses)
 * @param {*} param
 */
function CourseCategoriesChips({ chipData, handleCourseCategory }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
      boxShadow: "none",
    },
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: "rgba(24, 160, 251, 0.28)",
      color: "#18A0FB",
      borderRadius: "5px",
    },
  }));

  const classes = useStyles();

  return (
    <>
      {chipData.map((data, index) => {
        // console.log("Check individual chip data", data);
        return (
          <Chip
            key={data.id + "CategoryChips"}
            label={data.name}
            onClick={() => handleCourseCategory(data)}
            className={classes.chip}
          />
        );
      })}
    </>
  );
}

export { CourseCategoriesChips };
