/* eslint-disable import/no-anonymous-default-export */
import facebook from "../assets/SocialMedia/facebook.png";
import linkedin from "../assets/SocialMedia/linkedin.png";
import instagram from "../assets/SocialMedia/instagram.png";
import youtube from "../assets/SocialMedia/youtube.png";
import website from "../assets/SocialMedia/website.png";

export default {
  FACEBOOK: facebook,
  LINKEDIN: linkedin,
  INSTAGRAM: instagram,
  YOUTUBE: youtube,
  WEBSITE: website,
};
