import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import "./DragDrop.scss";

function DragDrop(props) {
  console.log("Props Image Name", props.imagename);
  let { id } = useParams();
  const [fileNames, setFileNames] = useState("");

  const handleDrop = (acceptedFiles) => {
    console.log("Check Accepted files", acceptedFiles);
    var fr = new FileReader();
    fr.readAsDataURL(acceptedFiles[0]);
    fr.onload = function (e) {
      setFileNames(this.result);
    };
    // setFileNames(URL.createObjectURL(acceptedFiles[0]));
  };

  useEffect(() => {}, []);

  return (
    <div className="container">
      <div className="row ">
        <div className="col-md-12 justify-center">
          <div className={props.styles}>
            <Dropzone
              onDrop={(file) => {
                props.handleDrop(file);
                handleDrop(file);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} accept="image/*" />

                  {id === undefined && props.imagename.length === 0 && (
                    <h5 className="fileupload__text">Drag file to upload</h5>
                  )}

                  {id === undefined &&
                    props.imagename.length > 0 &&
                    props.imagename[0] && (
                      <>
                        <div className="drop__image__container">
                          <img
                            src={fileNames}
                            alt="img"
                            className="drop__image"
                          />
                        </div>
                      </>
                    )}
                  {id !== undefined && (
                    <div className="drop__image__container">
                      <img
                        src={
                          props.imagename.length > 0
                            ? fileNames
                            : IMAGECONFIG.IMAGECONFIG.BANNER +
                              props.data.media_id
                        }
                        alt="img"
                        className="drop__image"
                      />
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DragDrop;
