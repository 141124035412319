import React, { useState } from "react";
import "./Section.scss";

function Survey({
  data,
  teachers,
  students,
  learntoteachonline,
  handleBoolean,
  handleDrop,
  profilepic,
  handleRadioBoolean,
  profilepicname,
}) {
  const [checks, setChecks] = useState({
    teacher: false,
    student: false,
    teachOnline: false,
  });
  return (
    <div className="row justify-center">
      <div className="col-md-8">
        <div className="flex-row justify-between">
          <div className="flex-column">
            {/* <h4 className="form__heading ">
              Are you love to learn or teach online?{" "}
            </h4> */}
            {/* <h6 className="form__heading">
              (Are you here to learn or teach today?)
            </h6> */}
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={learntoteachonline}
              checked={learntoteachonline}
              id="flexCheckDefault"
              onClick={() => handleBoolean("learntoteachonline")}
            />
          </div>
        </div>
        <h3>Are you here to learn or teach today?</h3>

        <div style={{ height: "50px" }} />
        <div className="flex-row justify-between">
          <label htmlFor="teacher" className="form__heading">
            Teacher
          </label>
          <div className="form-check">
            <input
              checked={teachers}
              className="form-check-input"
              type="checkbox"
              value={teachers}
              id="teacher"
              onClick={() => handleBoolean("teacher")}
            />
          </div>
          <label htmlFor="student" className="form__heading">
            Student
          </label>
          <div className="form-check">
            <input
              checked={students}
              className="form-check-input"
              type="checkbox"
              value={students}
              id="student"
              onClick={() => handleBoolean("student")}
            />
          </div>
        </div>
        <div style={{ height: "60px" }} />
        {/*******************  SECTION ONE ************************** */}
        {teachers && (
          <>
            <h4 className="form__heading">
              What kind of teaching have you done before?
            </h4>
            <div style={{ height: "40px" }} />
            <form action="">
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="one"
                      name="one"
                      value="one"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "teachingdonebefore",
                          "In person, informally"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    In person, informally
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="one"
                      name="one"
                      value="one"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "teachingdonebefore",
                          "In person, professionally"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    In person, professionally
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="one"
                      name="one"
                      value="one"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean("teachingdonebefore", "Online")
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    Online
                  </h5>
                </div>
              </div>

              <div style={{ height: "30px" }} />

              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="one"
                      name="one"
                      value="one"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean("teachingdonebefore", "Other")
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    Other
                  </h5>
                </div>
              </div>
            </form>
            <div style={{ height: "60px" }} />
            {/*******************  SECTION TWO ************************** */}

            <h4 className="form__heading">
              How much of a video “pro” are you?
            </h4>
            <div style={{ height: "40px" }} />
            <form action="">
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="two"
                      name="two"
                      value="two"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean("videopro", "I’m a beginner")
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I’m a beginner
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="two"
                      name="two"
                      value="two"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean("videopro", "I have some knowledge")
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I have some knowledge
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="two"
                      name="two"
                      value="two"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean("videopro", "I’m experienced")
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I’m experienced
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="two"
                      name="two"
                      value="two"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "videopro",
                          "I have videos ready to upload"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I have videos ready to upload
                  </h5>
                </div>
              </div>
            </form>
            <div style={{ height: "60px" }} />
            {/*******************  SECTION THREE ************************** */}

            <h4 className="form__heading">
              Do you have an audience to share your course with?
            </h4>
            <div style={{ height: "40px" }} />
            <form action="">
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="three"
                      name="three"
                      value="three"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "audiencetoshare",
                          "Not at the moment"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    Not at the moment
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="three"
                      name="three"
                      value="three"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "audiencetoshare",
                          "I have a small following"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I have a small following{" "}
                  </h5>
                </div>
              </div>
              <div style={{ height: "30px" }} />
              <div className="form-group">
                <div className="form-control flex-row">
                  <label htmlFor="">
                    <input
                      type="radio"
                      id="three"
                      name="three"
                      value="three"
                      className="radio__button"
                      onClick={() =>
                        handleRadioBoolean(
                          "audiencetoshare",
                          "I have a sizeable following"
                        )
                      }
                    />
                  </label>

                  <h5 className="form__subheading margin__left__2px margin__left__15px">
                    I have a sizeable following
                  </h5>
                </div>
              </div>
            </form>
          </>
        )}
        <div style={{ height: "60px" }} />
      </div>
    </div>
  );
}

export default Survey;
