import React, { useState, useEffect } from "react";
import yoga1 from "../../assets/Courses/watchHistory/yoga1.jpeg";
import uiux2 from "../../assets/Courses/watchHistory/uiux2.jpeg";
import chords from "../../assets/Courses/watchHistory/chords.png";
import code from "../../assets/Courses/watchHistory/code.jpeg";
import abstractpaint from "../../assets/Courses/watchHistory/abstractpaint.jpeg";
import music from "../../assets/Courses/watchHistory/music.jpeg";
import Options from "../../components/Options";
import WatchHistory from "../../components/WatchHistory/WatchHistory";
import Footer from "../../components/Footer/Footer";
import { getwatchlist } from "../../Core/coreapicalls";
import { LoaderContainer } from "../../StyleConfig/Common/CommonStyles";
import { FadeLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";

const sections = [
  {
    id: 1,
    name: "Explore",
    path: "/courses/explore",
  },
  {
    id: 2,
    name: "My Courses",
    path: "/courses/mycourses",
  },
  {
    id: 3,
    name: "Watch History",
    path: "/courses/watchhistory",
  },
];

function WatchCourseHistory() {
  const [watchList, setWatchList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getwatchlistapi = () => {
    setLoading(true);
    let reqBody = {
      api_token: JSON.parse(localStorage.getItem("token")),
      user_id: JSON.parse(localStorage.getItem("userId")),
    };
    getwatchlist(reqBody)
      .then((res) => {
        setLoading(false);
        console.log("Check Watch History Response", res);
        if (res.status === true) {
          setWatchList(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log("Check Watch history error", err);
      });
  };

  useEffect(() => {
    setWatchList([]);
    getwatchlistapi();
  }, []);

  useEffect(() => {
    return () => {
      console.log("Empty Watchlist", watchList);
    };
  }, []);

  return (
    <div className="my_courses_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <Options value={sections} section={"MyCourse"} />
            </div>
            {loading === false && watchList !== [] && (
              <div>
                <WatchHistory
                  watchHistory={watchList}
                  category={"WatchHistory"}
                />
              </div>
            )}
            {loading && (
              <LoaderContainer>
                <FadeLoader
                  color={Theme.LIGHT.INNERLOADER}
                  loading={true}
                  size={50}
                />
              </LoaderContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WatchCourseHistory;
