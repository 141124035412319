import React from "react";
import "./HorizontalCard.scss";

function HorizontalCard({ imagePath }) {
  return (
    <div className="container-fluid padding40__px">
      <div className="row">
        <div className="col-md-12">
          <div style={{ height: "20px" }} />
          <div className="card horizontal__card mb-3">
            <div className="row g-0">
              <div className="col-md-4">
                <img src={imagePath} alt="..." className="img__style" />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h4 className="card__title">Musical Notes</h4>
                  <p className="card-text">
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem.
                  </p>
                  <p className="card-text">
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HorizontalCard;
