import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import AppHeader from "../../../components/AppHeader/AppHeader";
import Profile from "../../../assets/profile/profilepic.png";
import SocialMediaIcons from "../../../StyleConfig/SocialMediaIcons";
import "./EditProfile.scss";
import { useDataLayerValue } from "../../../context/DataLayer";
import { editloginuserprofile, getmycourses } from "../../../Core/coreapicalls";
import CoursesCard from "../../../components/CoursesCard/CoursesCard";
import {
  ProfileImage,
  ProfileImageContainer,
  ProfilePageContainer,
} from "../../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../../StyleConfig/MediaImage";
import Loader from "../../../components/Loader/Loader";
import AppSnackbar from "../../../components/AppSnackbar/AppSnackbar";
import CreateIcon from "@material-ui/icons/Create";

function EditProfile(props) {
  let history = useHistory();
  // const profiledetails = props.location.state.profile;

  const [responseMessage, setResponseMessage] = useState("");
  const [editCourses, setEditCourses] = useState([]);
  const [uploadProfile, setUploadProfile] = useState([]);
  const [editProfileValues, setEditProfileValues] = useState({
    name: "",
    aboutme: "",
    profilepic: "",
    imagestatus: "",
    designation: "",
    userlocation: "",
    fburl: "",
    instaurl: "",
    linkedinurl: "",
    youtubeurl: "",
    websiteurl: "",
    media_id: "",
    success: false,
    error: false,
  });

  const {
    name,
    aboutme,
    profilepic,
    media_id,
    imagestatus,
    designation,
    userlocation,
    fburl,
    instaurl,
    linkedinurl,
    youtubeurl,
    websiteurl,
    success,
    error,
  } = editProfileValues;

  const handleChange = (name) => (event) => {
    setEditProfileValues({ ...editProfileValues, [name]: event.target.value });
  };

  const onsubmit = (event) => {
    event.preventDefault();
    console.log("Check Upload profile data on submit", uploadProfile);
    props.setLoading(true);
    let reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      name: name,
      aboutme: aboutme,
      imagestatus: imagestatus,
      uploadprofilepic: uploadProfile,
      media_id: media_id,
      designation: designation,
      userlocation: userlocation,
      fburl: fburl,
      instaurl: instaurl,
      linkedinurl: linkedinurl,
      youtubeurl: youtubeurl,
      websiteurl: websiteurl,
    };
    editloginuserprofile(reqBody)
      .then((data) => {
        console.log("Check Updated profile response", data);
        props.setLoading(false);
        if (data.status === true) {
          setResponseMessage("Profile Updated.");
          setEditProfileValues({
            ...editProfileValues,
            success: true,
            error: false,
          });
          setTimeout(() => {
            history.push("/myprofile");
          }, 2000);
        }
      })
      .catch((error) => {
        props.setLoading(false);
        setResponseMessage("Something went wrong.");
        setEditProfileValues({
          ...editProfileValues,
          success: false,
          error: true,
        });
        console.log(error);
      });
  };

  /**
   * Get My Course Api Call
   */
  const getcourse = () => {
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getmycourses(reqBody)
      .then((data) => {
        if (data.status === true) {
          setEditCourses(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleProfilePic = (e) => {
    console.log("Check Handle Profile pic value", e);
    setEditProfileValues({
      ...editProfileValues,
      profilepic: URL.createObjectURL(e.target.files[0]),
    });
  };

  useEffect(() => {
    console.log(
      "Check user profile data from localstorage",
      JSON.parse(localStorage.getItem("userprofiledata"))
    );
    let details = JSON.parse(localStorage.getItem("userprofiledata"));
    setEditProfileValues({
      ...editProfileValues,
      name: details.name,
      aboutme: details.about_me,
      imagestatus: details.img_status,
      profilepic: IMAGECONFIG.IMAGECONFIG.PROFILE + details.media_id,
      media_id: details.media_id,
      designation: details.profession,
      userlocation: details.location,
      fburl: details.facebook,
      instaurl: details.instagram,
      linkedinurl: details.linkdin,
      youtubeurl: details.youtube,
    });
    getcourse();
    return () =>
      setEditProfileValues({
        ...editProfileValues,
        name: "",
        aboutme: "",
        imagestatus: "",
        profilepic: "",
        designation: "",
        userlocation: "",
        fburl: "",
        instaurl: "",
        linkedinurl: "",
        youtubeurl: "",
      });
  }, []);

  return (
    <ProfilePageContainer>
      <AppHeader />
      <div className="container">
        <form>
          <div className="row">
            <div className="col-md-4">
              <div className="profile__image__container">
                <ProfileImageContainer>
                  <label htmlFor="edit-profile-image">
                    <ProfileImage src={profilepic} alt="profile pic" />
                  </label>

                  <label
                    htmlFor="edit-profile-image"
                    style={{
                      backgroundColor: "#2C7DE7",
                      borderRadius: "4px",
                      position: "absolute",
                      bottom: "0px",
                      left: "170px",
                    }}
                  >
                    <CreateIcon
                      style={{
                        color: "#FFF",
                        margin: "2px",
                        cursor: "pointer",
                      }}
                    />
                  </label>
                  <input
                    style={{ display: "none" }}
                    id="edit-profile-image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setUploadProfile(e.target.files[0]);
                      handleProfilePic(e);
                    }}
                  />
                </ProfileImageContainer>
              </div>
              <div className="information__container width350__px">
                <div className="form-group">
                  <label className="text-light">Name</label>
                  <input
                    placeholder="Name"
                    className="form-control"
                    onChange={handleChange("name")}
                    type="text"
                    value={name}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light">Designation</label>
                  <input
                    placeholder="Designation"
                    className="form-control"
                    onChange={handleChange("designation")}
                    type="text"
                    value={designation}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light">Country</label>
                  <input
                    placeholder="Country"
                    className="form-control"
                    onChange={handleChange("userlocation")}
                    type="text"
                    value={userlocation}
                  />
                </div>
                <div className="form-group">
                  <img
                    src={SocialMediaIcons.FACEBOOK}
                    alt="facebook"
                    className="social__logo__inform shadow"
                  />
                  <label className="text-light">Facebook url...</label>
                  <input
                    className="form-control padding__left__60px"
                    placeholder="Facebook url..."
                    onChange={handleChange("fburl")}
                    type="text"
                    value={fburl}
                  />
                </div>
                <div className="form-group">
                  <img
                    src={SocialMediaIcons.INSTAGRAM}
                    alt="instagram "
                    className="social__logo__inform shadow"
                  />
                  <label className="text-light">Instagram url...</label>
                  <input
                    className="form-control padding__left__60px"
                    placeholder="Instagram url..."
                    onChange={handleChange("instaurl")}
                    type="text"
                    value={instaurl}
                  />
                </div>
                <div className="form-group">
                  <img
                    src={SocialMediaIcons.LINKEDIN}
                    alt="linkedin"
                    className="social__logo__inform shadow"
                  />
                  <label className="text-light">LinkedIn url...</label>
                  <input
                    className="form-control padding__left__60px"
                    placeholder="LinkedIn url..."
                    onChange={handleChange("linkedinurl")}
                    type="text"
                    value={linkedinurl}
                  />
                </div>
                <div className="form-group">
                  <img
                    src={SocialMediaIcons.YOUTUBE}
                    alt="youtube"
                    className="social__logo__inform shadow"
                  />
                  <label className="text-light">Youtube url...</label>
                  <input
                    className="form-control padding__left__60px"
                    placeholder="Youtube url..."
                    onChange={handleChange("youtubeurl")}
                    type="text"
                    value={youtubeurl}
                  />
                </div>
                <div className="form-group">
                  <img
                    src={SocialMediaIcons.WEBSITE}
                    alt="website"
                    className="social__logo__inform shadow"
                  />
                  <label className="text-light">Website url...</label>
                  <input
                    className="form-control padding__left__60px"
                    placeholder="Website url..."
                    onChange={handleChange("websiteurl")}
                    type="text"
                    value={websiteurl}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div style={{ height: "20px" }} />
              <div className="flex-column ">
                <div className="flex-end ">
                  <Link
                    to={{
                      pathname: "/myprofile",
                    }}
                  >
                    <button className="btn btn-outline-primary cancel__button">
                      <span className="button__title">Cancel</span>
                    </button>
                  </Link>

                  <button
                    // type="submit"
                    onClick={onsubmit}
                    className="btn btn-primary savexit__button"
                  >
                    <span className="button__title">Save & Exit</span>
                  </button>
                </div>
                <div className="flex-end">
                  <Link
                    to={{
                      pathname: `/askabout/edit`,
                    }}
                  >
                    <button className="btn btn-primary log__out">
                      <span className="button__title">
                        Modify Question Answer(s)
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="aboutme__section">
                <h2 className="about__me">About Me</h2>
                <div className="form-group">
                  <textarea
                    style={{ height: "200px" }}
                    placeholder="About Me"
                    className="form-control"
                    onChange={handleChange("aboutme")}
                    type="text"
                    value={aboutme}
                  />
                </div>
              </div>

              {/* <div className="mycourses__section">
                <h4>Courses</h4>
                <CoursesCard courses={editCourses} location={location} />
              </div> */}
            </div>
          </div>
        </form>
      </div>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
    </ProfilePageContainer>
  );
}

export default Loader(EditProfile);
