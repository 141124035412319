import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDataLayerValue } from "../../context/DataLayer";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
    border: "0px solid #fff !important",
    borderRadius: "5px",
    width: "40%",
  },
}));

function AppModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [{ openModal }, dispatch] = useDataLayerValue();

  useEffect(() => {
    if (props.openModal) {
      document.querySelector("body").classList.add("overflow--hidden");
    } else {
      document.querySelector("body").classList.remove("overflow--hidden");
      document.querySelector("body").style.overflow = "auto";
    }
  }, [props.openModal]);

  return (
    <Modal
      className={classes.modal}
      open={props.openModal}
      onClose={() => props.handleClose(props.name)}
      closeAfterTransition
      disableBackdropClick={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade
        in={open}
        style={{
          opacity: "inherit",
          visibility: "visible",
        }}
      >
        <div className={classes.paper}>{props.children}</div>
      </Fade>
    </Modal>
  );
}

export default AppModal;
