/* eslint-disable import/no-anonymous-default-export */
export default {
  LIGHT: {
    BG: "#F4F4F4",
    LOADER: "#7EFAFC",
    INNERLOADER: "#C70039",
    CARDBACKGROUND: "#FFFFFF",
    CARDBACKGROUNDTWO: "#F1F6F9",
    CARDSHADOW: "0 2px 4px 0 rgb(184 184 184 / 50%)",
    SECTIONTITLE: "#262626",
    COURSENAME: "#012432",
    COURSEDESCRIPTION: "#7f7b7b",
    TAGSCONTAINERBACKGROUND: "#394867",
    COURSEDURATION: "#7f7b7b",
    BUTTON: "#00adb5",
    FOLLOWUNFOLLOW: "#00adb5",
    CHIPONE: "rgba(255, 58, 102, 0.2);",
    CHIPONETEXT: "#FF3A66",
    CHIPTWOTEXT: "#FD7F44",
    CHIPTWO: "rgba(253, 127, 68, 0.41);",
    LECTURECARDBACKGROUNDONE: "#E4F0FD",
    LECTURECARDBACKGROUNTWO: "#F6E1E1",
    UPLOADPERCENTAGEBACKGROUND: "#51aeff",
    LECTUREPERCENTAGEONE: "#7F8FC9",
    LECTUREPERCENTAGETWO: "#EB4D4B",
  },
  DARK: {},
};
