import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/Logo/skilllight.png";
import { useDataLayerValue } from "../../context/DataLayer";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import "./AppHeader.scss";

function AppHeader(props) {
  const [{}, dispatch] = useDataLayerValue();
  const loginuserid = JSON.parse(localStorage.getItem("userId"));

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
      style={{ height: "60px" }}
    >
      <Link
        className="navbar-brand"
        to={{
          pathname: "/home",
        }}
      >
        <img
          src={logo}
          alt="skillo'hana"
          style={{ objectFit: "contain", margin: "10px" }}
          width="150px"
        />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse flex-end"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              to={{
                pathname: "/home",
              }}
              activeClassName="nav__active"
              className="nav-link"
            >
              <span>Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={{
                pathname: "/courses/explore",
              }}
              activeClassName="nav__active"
              className="nav-link"
            >
              <span>Courses</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link nav-avatar"
              to={{
                pathname: "/myprofile",
              }}
              onClick={() => {
                dispatch({
                  profileuserid: loginuserid,
                  type: "SET__PROFILEUSER__ID",
                });
              }}
            >
              <img
                src={
                  IMAGECONFIG.IMAGECONFIG.PROFILE +
                  JSON.parse(localStorage.getItem("mediaId"))
                }
                alt="Avatar"
                className="avatar"
                style={{
                  objectFit: "contain",
                }}
              />{" "}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default AppHeader;
