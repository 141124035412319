/* eslint-disable import/no-anonymous-default-export */
export default {
  FONTS: {
    APPFONT: "Poppins",
  },
  SIZE: {
    TWENTY: "20px",
    EIGHTEEN: "18px",
    SIXTEEN: "16px",
    FOURTEEN: "14px",
    TWELVE: "12px",
  },
  WEIGHT: {
    BOLD: "bold",
    NINEHUNDREED: 900,
    EIGHTHUNDRED: 800,
    SEVENHUNDRED: 700,
    SIXHUNDRED: 600,
    FIVEHUNDRED: 500,
    FOURHUNDRED: 400,
    THREEHUNDRED: 300,
    TWOHUNDRED: 200,
    HUNDRED: 100,
  },
};
