
export const API = 'https://api.skillohana.com/api/v1';



// export const API ='http://127.0.0.1:8000/api/v1';


export const VIDEOAPI = 'https://api.skillohana.com/';
export const DEVAPI = process.env.DEV_API;
