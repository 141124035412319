import React, { createContext, useContext, useReducer, useState } from "react";

export const DataLayerContext = createContext();

export const InterestContext = createContext();

export const DataLayer = ({ initialState, reducer, children }) => (
  <DataLayerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataLayerContext.Provider>
);

export const useDataLayerValue = () => useContext(DataLayerContext);

export const InterestProvider = (props) => {
  const [interest, setInterest] = useState([]);
  return (
    <InterestContext.Provider value={[interest, setInterest]}>
      {props.children}
    </InterestContext.Provider>
  );
};
