import React from "react";
import { FadeLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";

function InnerLoader() {
  return (
    <div>
      <FadeLoader color={Theme.LIGHT.INNERLOADER} loading={true} size={50} />
    </div>
  );
}

export default InnerLoader;
