/**
 * ALL LOADING SHIMMER EFFECTS
 */

import styled from "styled-components";
import { keyFrameExampleOne } from "./Keyframes";

const ShimmerEffect = styled.img`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${keyFrameExampleOne};
  animation-timing-function: linear;
  background: #fff;
  border: none;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 100%;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  position: relative;
`;

export { ShimmerEffect };

//linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%)
