import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  SaveButton,
  SaveButtonText,
} from "../../../StyleConfig/Common/CommonStyles";
import AppProgressBar from "../../../components/AppProgressBar/AppProgressBar";
import {
  courselecturedit,
  uploadcourselecture,
} from "../../../Core/coreapicalls";
import { useDataLayerValue } from "../../../context/DataLayer";
import CancelIcon from "@material-ui/icons/Cancel";
import AppSnackbar from "../../../components/AppSnackbar/AppSnackbar";
// import videofile from "../../../assets/video/uiux.mp4";
import { VIDEOAPI } from "../../../backend";

function AddLectures({
  uniqueid,
  courseData,
  getLectures,
  handleClose,
  editLecture,
}) {
  const { id } = useParams();
  const [{ addeditlecture }, dispatch] = useDataLayerValue();
  const [responseMessage, setResponseMessage] = useState("");
  const [values, setValues] = useState({
    lectureTitle:
      editLecture === "" || addeditlecture === "addlecture"
        ? ""
        : editLecture.title,
    lectureDuration:
      editLecture === "" || addeditlecture === "addlecture"
        ? ""
        : editLecture.duration,
    loading: false,
    success: false,
    error: false,
  });
  const [lectureVideo, setLectureVideo] = useState([]);
  const [displayVideo, setDisplayVideo] = useState(
    editLecture === "" || addeditlecture === "addlecture"
      ? ""
      : VIDEOAPI + editLecture.file
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { lectureTitle, lectureDuration, loading, success, error } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleVideoChange = (file) => {
    var fr = new FileReader();
    fr.readAsDataURL(file[0]);
    fr.onload = function (e) {
      setDisplayVideo(this.result);
    };
  };

  const onEditLecture = (e) => {
    console.log("Lecture Edit Api Call");
    e.preventDefault();
    setValues({ ...values, loading: true });
    const reqBody = {
      courses_id: courseData.courseid,
      id: editLecture.id,
      prev_path: editLecture.file,
      lectureTitle: lectureTitle,
      lectureDuration: lectureDuration,
      lectureVideo: e.target.files[0],
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
    };
    courselecturedit(reqBody, config)
      .then((res) => {
        console.log("Lecture Update Response", res);
        setValues({ ...values, loading: false });
        if (res.status === true) {
          getLectures();
          setResponseMessage("Lecture updated successfully.");
          setValues({ ...values, error: false, success: true });
        }
      })
      .catch((err) => {
        setResponseMessage("Something went wrong.");
        setValues({ ...values, loading: false, success: false, error: true });
        console.log("Check err", err);
      });
  };

  const onsubmit = (e) => {
    console.log("Lecture Submit Api Call");
    e.preventDefault();
    setValues({ ...values, loading: true });
    const reqBody = {
      id: uniqueid,
      courses_id: courseData ? courseData.courseid : "",
      lectureTitle: lectureTitle,
      lectureDuration: lectureDuration,
      lectureVideo: e.target.files[0],
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
    };
    uploadcourselecture(reqBody, config)
      .then((res) => {
        console.log("Check Res for progress", res);
        setValues({ ...values, loading: false });
        if (res.status === true) {
          getLectures();
          setResponseMessage("Lecture added successfully.");
          setValues({ ...values, error: false, success: true });
        }
      })
      .catch((err) => {
        setResponseMessage("Something went wrong.");
        setValues({ ...values, loading: false, success: false, error: true });
        console.log("Check err", err);
      });
  };

  useEffect(() => {
    console.log("Check Add Edit Lecture", addeditlecture);
    console.log("Unique id", uniqueid);
    console.log("Course Id in add lecture", id);
    console.log("Lecture Video", lectureVideo.name);
    console.log("Check Edit Lecture Video", editLecture);

    return () => {};
  }, []);

  return (
    <div>
      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            // dispatch({
            //   type: "SET__MODAL__VALUE",
            //   openModal: false,
            // });
            handleClose("addlecture");
          }}
        >
          <CancelIcon />
        </div>
        <div className="form-group">
          <h6>Lecture Title</h6>
          <input
            className="form-control"
            placeholder="Lecture Title"
            onChange={handleChange("lectureTitle")}
            type="text"
            value={lectureTitle}
            required
          />
        </div>
        <div style={{ height: "20px" }} />
        <div className="form-group">
          <h6>Duration</h6>
          <input
            className="form-control"
            placeholder="eg: 01.00 hr"
            onChange={handleChange("lectureDuration")}
            type="text"
            value={lectureDuration}
            required
          />
        </div>
        <div style={{ height: "20px" }} />
        <div className="flex-row space">
          <h6>Upload Lecture Video</h6>
          <div className="image-upload">
            <label htmlFor="file-input">
              <AddCircleIcon
                style={{ color: "#1EA69A", margin: "2px", cursor: "pointer" }}
              />
            </label>
            <input
              id="file-input"
              type="file"
              accept="video/*"
              onChange={(e) => {
                handleVideoChange(e.target.files);
                // onsubmit(e)
                if (addeditlecture === "addlecture") {
                  onsubmit(e);
                } else {
                  onEditLecture(e);
                }
              }}
            />
          </div>
        </div>
        {displayVideo !== "" && (
          <div className="d-flex justify-center">
            <video src={displayVideo} width="300" controls />
          </div>
        )}
        <div style={{ height: "20px" }} />
        <AppProgressBar percentage={uploadPercentage} />
        {lectureVideo && <h6>{lectureVideo.name}</h6>}
        <div className="flex-row justify-center">
          <SaveButton
            disabled={loading}
            onClick={() => handleClose("addlecture")}
            className="btn"
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <SaveButtonText>Save</SaveButtonText>
            )}
          </SaveButton>
        </div>
      </form>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
    </div>
  );
}

export default AddLectures;
