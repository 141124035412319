import React, { useEffect } from "react";
import { useDataLayerValue } from "../../context/DataLayer";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import SettingsBackupRestoreRoundedIcon from "@material-ui/icons/SettingsBackupRestoreRounded";
import { VIDEOAPI } from "../../backend";
import "./VideoPlayer.scss";

function VideoPlayer({ courseDetails, courseLectures }) {
  const [{ videopath }] = useDataLayerValue();

  const handleVideo = (action) => {
    var a = document.querySelector("video");

    // a.currentTime += element;
    switch (action) {
      case "forward":
        a.currentTime += 5;
        break;
      case "backward":
        a.currentTime -= 5;
        break;
      case "reset":
        a.currentTime = 0;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("Check Video Path", videopath);
  }, [videopath]);

  return (
    <div
      style={{
        marginTop: "10px",
        position: "relative",
      }}
    >
      <video
        onClick={() => console.log("Clicked")}
        src={VIDEOAPI + videopath?.file}
         playsInline fluid={false} width={900} height={500}    //this is g size
       
        controls
      ></video>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          right: 150,
          display: "flex",
        }}
      >
        <div onClick={() => handleVideo("backward")}>
          <ArrowBackIosRoundedIcon
            style={{ color: "white", marginRight: "10px" }}
          />
        </div>
        <div onClick={() => handleVideo("forward")}>
          <ArrowForwardIosRoundedIcon
            style={{ color: "white", marginRight: "10px" }}
          />
        </div>

        <div onClick={() => handleVideo("reset")}>
          <SettingsBackupRestoreRoundedIcon
            style={{ color: "white", marginRight: "10px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
