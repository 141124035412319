import React from "react";
import { useMediaQuery } from "react-responsive";

function Responsive(WrappedComponent) {
  function HOC(props) {
    const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
    const isDesktopOrLaptop = useMediaQuery({
      query: "(min-device-width: 1224px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    return (
      <WrappedComponent
        {...props}
        isDesktopOrLaptop={isDesktopOrLaptop}
        isBigScreen={isBigScreen}
        isTabletOrMobile={isTabletOrMobile}
      />
    );
  }
  return HOC;
}

export default Responsive;
