import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import { signin, socialsignup } from "../../auth/helper";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import "./SignIn.scss";
import {
  ContentBox,
  FacebookButton,
  FacebookTitle,
  GoogleButton,
  GoogleTitle,
} from "../../StyleConfig/Common/CommonStyles";
import facebookwhite from "../../assets/SocialMedia/facebookWhite.png";
import Google from "../../assets/SocialMedia/google.png";
import SocialMediaAuth from "../../auth/helper/SocialMediaAuth";
import {
  facebookProvider,
  googleProvider,
} from "../../auth/helper/AuthMethods";
import { useDataLayerValue } from "../../context/DataLayer";

function SignIn({ setLoading }) {
  let history = useHistory();
  console.log("Check History", history.location.state?.from.pathname);
  const [{}, dispatch] = useDataLayerValue();
  // USER SIGNIN DETAILS
  const [values, setValues] = useState({
    email: "",
    password: "",
    loading: false,
    error: false,
    success: false,
    warning: false,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const { email, password, error, loading, success, warning } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleOnClick = async (provider, loginType) => {
    console.log("Check Provider", provider, "Login Type", loginType);
    setValues({ ...values, warning: false, error: false, success: false });
    const result = await SocialMediaAuth(provider);
    console.log("Check Social media result", result);
    if (result.code === "auth/popup-closed-by-user") {
      setResponseMessage("Unable to login");
      setValues({ ...values, warning: true });
    }
    if (result.displayName && result.email !== undefined) {
      const displayName = result.displayName;
      const email = result.email;
      socialsignup({
        displayName,
        email,
        loginType,
      })
        .then((data) => {
          console.log("Check social sign up Response", data);
          if (data.status === true) {
            localStorage.setItem("token", JSON.stringify(data.data[0].token));
            localStorage.setItem(
              "userId",
              JSON.stringify(data.data[0].user_id)
            );
            localStorage.setItem("username", JSON.stringify(data.data[0].name));
            localStorage.setItem(
              "mediaId",
              JSON.stringify(data.data[0].media_id)
            );
            setResponseMessage("Login Successfull");
            setValues({
              ...values,
              error: false,
              warning: false,
              success: true,
            });
            if (data.data[0].Q6_status === 0) {
              setTimeout(() => {
                history.push("/askabout");
              }, 2000);
            } else {
              setTimeout(() => {
                if (history.location.state?.from.pathname) {
                  history.push(history.location.state?.from.pathname);
                } else {
                  history.push("/home");
                }
              }, 2000);
            }
          } else if (data.status === false) {
            setResponseMessage("Something wrong!");
            setValues({ ...values, success: false, error: true });
          } else {
            setResponseMessage(data.msg);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      console.log("No data found");
    }
  };

  /**
   * Login Api Call
   * @param {*} event
   */
  const onsubmit = (event) => {
    event.preventDefault();
    let reqBody = {
      email,
      password,
    };
    // setLoading(true);
    setValues({
      ...values,
      error: false,
      success: false,
      warning: false,
      loading: true,
      password: false,
    });
    signin(reqBody)
      .then((data) => {
        console.log("Check Sign in data ->>", data);
        // setLoading(false);
        if (data.status === true) {
          localStorage.setItem("token", JSON.stringify(data.data[0].token));
          localStorage.setItem("userId", JSON.stringify(data.data[0].user_id));
          localStorage.setItem("username", JSON.stringify(data.data[0].name));

          localStorage.setItem(
            "mediaId",
            JSON.stringify(data.data[0].media_id)
          );

          setResponseMessage("Log in Successful");
          setValues({
            ...values,
            loading: false,
            error: false,
            warning: false,
            success: true,
            email: "",
            password: "",
          });
          var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"); //validation 



          if (data.data[0].Q6_status === 0) {
            setTimeout(() => {
              history.push("/askabout");
            }, 2000);
          } else {
            setTimeout(() => {
              if (history.location.state?.from.pathname) {
                history.push(history.location.state?.from.pathname);
              } else {
                history.push("/home");
              }
            }, 2000);
          }
        } else if (data.status === false) {
          setResponseMessage(data.msg);
          setValues({
            ...values,
            loading: false,
            error: false,
            success: false,
            warning: true,
          });
        } else {
          setResponseMessage(data.msg);
          setValues({
            ...values,
            loading: false,
            success: false,
            warning: false,
            error: true,
            password: "",
          });
        }
      })
      .catch((err) => {
        setResponseMessage("Something went wrong");
        setValues({
          ...values,
          loading: false,
          success: false,
          warning: false,
          error: true,
        });
        // setLoading(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="signin__container">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-8 offset-sm-2 text-center"
            bis_skin_checked="1"
          >
            <ContentBox>
              <div className="content-box">
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/")}
                >
                  <CancelIcon />
                </div>

                <div style={{ height: "15px" }} />
                <h5 className="text-center">Please login to continue</h5>
                <div style={{ height: "25px" }} />
                <div className="row">
                  <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-10 offset-sm-1">
                    <div className="d-flex space-even">
                      <div className="button">
                        <FacebookButton
                          type="button"
                          onClick={() =>
                            handleOnClick(facebookProvider, "Facebook")
                          }
                        >
                          <img
                            src={facebookwhite}
                            alt="facebook"
                            width="25px"
                            style={{
                              objectFit: "contain",
                              margin: "2px",
                            }}
                          />
                          <FacebookTitle>Login with Facebook</FacebookTitle>
                        </FacebookButton>
                      </div>
                      <div className="button">
                        <GoogleButton
                          type="button"
                          onClick={() => handleOnClick(googleProvider, "Gmail")}
                        >
                          <img
                            src={Google}
                            alt="google"
                            width="25px"
                            style={{
                              objectFit: "contain",
                              margin: "2px",
                            }}
                          />
                          <GoogleTitle>Login with Google</GoogleTitle>
                        </GoogleButton>
                      </div>
                    </div>
                    <div style={{ height: "15px" }} />

                    <form onSubmit={onsubmit}>
                      <div className="form-group">
                        <label className="control-label" for="user_email">
                          Email Address
                        </label>
                        <div style={{ height: "5px" }} />
                        <div className="control-input">
                          <input
                            className="form-control"
                            name="user[email]"
                            id="user_email"
                            onChange={handleChange("email")}
                            type="email"
                            value={email}
                            required
                          />
                        </div>
                      </div>
                      <div style={{ height: "15px" }} />

                      <div className="form-group">
                        <label className="control-label" for="user_email">
                          Password
                        </label>
                        <div style={{ height: "5px" }} />
                        <div className="control-input">
                          <input
                            className="form-control"
                            name="user[password]"
                            id="user_password"
                            type="password"
                            value={password}
                            required
                            onChange={handleChange("password")}
                          />
                        </div>
                      </div>
                      <div style={{ height: "25px" }} />
                      <button
                        // onClick={onSubmit}
                        type={"submit"}
                        className="btn btn-block signupform__button"
                      >
                        {loading ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <span>Sign In</span>
                        )}
                      </button>
                    </form>
                    <div style={{ height: "20px" }} />
                    <div className="flex-row justify-between">
                      <Link
                        to={{
                          pathname: "/signup",
                        }}
                      >
                        <span className="flex-end apptext__color">
                          Don't have account?{" "}
                          <span style={{ color: "#1877f2", marginLeft: "2px" }}>
                            sign up
                          </span>
                        </span>
                      </Link>
                      <Link
                        to={{
                          pathname: "/forgetpassword",
                        }}
                      >
                        <span
                          className="flex-end apptext__color"
                          style={{ color: "#1877f2" }}
                        >
                          Forgot Password?
                        </span>
                      </Link>
                    </div>
                    <div style={{ height: "20px" }} />
                  </div>
                </div>
              </div>
            </ContentBox>
          </div>
        </div>
      </div>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
      {warning && <AppSnackbar message={responseMessage} severity="warning" />}
    </div>
  );
}

export default SignIn;
