/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import SteeperSection from "../../components/SteeperSection/SteeperSection";
import AboutYourself from "./Sections/AboutYourself";
import AreaofInterest from "./Sections/AreaofInterest";
import Achievements from "./Sections/Achievements";
import UploadProfiles from "./Sections/UploadProfiles";
import AddLinks from "./Sections/AddLinks";
import Loader from "../../components/Loader/Loader";
import { InterestContext, useDataLayerValue } from "../../context/DataLayer";
import { askaboutinfo, getloginuserprofile } from "../../Core/coreapicalls";
import "./AskAbout.scss";
import Survey from "./Sections/Survey";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import Footer from "../../components/Footer/Footer";
import AppModal from "../../components/AppModal/AppModal";
import { LoaderContainer } from "../../StyleConfig/Common/CommonStyles";
import { FadeLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";

function AskAbout() {
  let history = useHistory();
  let { id } = useParams();
  // const [{ profilepic, dispatch }] = useDataLayerValue();
  /**
   * Interest Context
   * */
  const [interest, setInterest] = useContext(InterestContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  /**
   * For input fields
   *  */
  const [data, setData] = useState({
    name: "",
    aboutYourself: "",
    areaofinterest: interest,
    achievements: "",
    facebookurl: "",
    instagramurl: "",
    linkedinurl: "",
    youtubeurl: "",
    websiteurl: "",
    media_id: 0,
  });

  /**
   * Online learning hook for Checkbox in update profile section
   *  */
  const [learntoteachonline, setLearntoteachonline] = useState(false);

  /**
   * For Teacher / Student hook for Checkbox
   *  */
  const [teacher, setTeacher] = useState(false);
  const [student, setStudent] = useState(false);

  /**
   * Drag and drop profile picture data and send to api /
   * profile pic update name hook in update profile section
   * */

  const [profilepic, setProfilePic] = useState([]);
  const [profilepicname, setProfilePicName] = useState([]);

  /**
   * Teaching Done, Video Pro, Audience Share Radio button hook.
   */

  const [teachingdonebefore, setTeachingdonebefore] = useState("");
  const [videopro, setVideopro] = useState("");
  const [audiencetoshare, setAudiencetoshare] = useState("");

  /**
   * Media id, User id, Token Hook getting for localstorage.
   */
  const [mediaid, setMediaid] = useState(0);
  const [userid, setUserid] = useState(0);
  const [apitoken, setApitoken] = useState("");

  /**
   *
   * @param {*} name check box name for online learn, teacher, student
   * @param {*} state
   * @returns (Boolean values for online learn, teacher, student)
   */
  const handleBoolean = (name, state) => {
    switch (name) {
      case "learntoteachonline":
        return setLearntoteachonline(!learntoteachonline);
      case "teacher":
        return setStudent(false), setTeacher(true);
      case "student":
        return setTeacher(false), setStudent(true);
      default:
        return 0;
    }
  };

  /**
   *
   * @param {*} name Radio button names for Teaching done, video pro, audience to share
   * @param {*} string Radio button fields values
   * @returns
   */

  const handleRadioBoolean = (name, string) => {
    switch (name) {
      case "teachingdonebefore":
        return setTeachingdonebefore(string);
      case "videopro":
        return setVideopro(string);
      case "audiencetoshare":
        return setAudiencetoshare(string);
    }
  };
  /**
   *
   * @param {*} acceptedFiles Profile Pic Data
   */
  const handleDrop = (acceptedFiles) => {
    console.log("Check Accepted files", acceptedFiles);
    setProfilePic(acceptedFiles[0]);
    setProfilePicName(acceptedFiles.map((file) => file.name));
  };

  const {
    name,
    aboutYourself,
    achievements,
    facebookurl,
    instagramurl,
    linkedinurl,
    youtubeurl,
    websiteurl,
  } = data;

  /**
   *
   * @param {*} name Input field name to change
   * @returns
   */
  const handleChange = (name) => (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  /**
   * ASK ABOUT API CALL
   */

  const onsubmit = () => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    askaboutinfo({
      name: name,
      aboutYourself: aboutYourself,
      interest: [...new Set(interest)],
      profilepic: profilepic,
      achievements: achievements,
      facebookurl: facebookurl,
      instagramurl: instagramurl,
      linkedinurl: linkedinurl,
      youtubeurl: youtubeurl,
      websiteurl: websiteurl,
      learntoteachonline: learntoteachonline,
      teacher: teacher,
      student: student,
      teachingdonebefore: teachingdonebefore,
      videopro: videopro,
      audiencetoshare: audiencetoshare,
      apitoken: apitoken,
      userid: userid,
      mediaid: mediaid,
    })
      .then((data) => {
        console.log("Check ask about api response", data);
        setLoading(false);
        if (data.status === true) {
          setResponseMessage("Profile Submitted.");
          setSuccess(true);
          setError(false);
          setTimeout(() => {
            history.push("/home");
          }, 1000);
        }
        if (data.status === false) {
          setResponseMessage(data.msg);
          setSuccess(false);
          setError(true);
        }
      })
      .catch((error) => {
        console.log("Check error for ask about api");
        setResponseMessage("Something went wrong.");
        setLoading(false);
        setSuccess(false);
        setError(true);
        setActiveStep(0);
      });
  };

  const getuserdata = () => {
    setApitoken(JSON.parse(localStorage.getItem("token")));
    setMediaid(JSON.parse(localStorage.getItem("mediaId")));
    setUserid(JSON.parse(localStorage.getItem("userId")));
    setData({ ...data, name: JSON.parse(localStorage.getItem("username")) });
  };

  const getprofileapi = () => {
    setLoading(true);
    let reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      id: JSON.parse(localStorage.getItem("userId")),
    };
    getloginuserprofile(reqBody)
      .then((data) => {
        setLoading(false);

        if (data.status === true) {
          console.log("Profile data for Edit Q6 -->>", data.data);
          let userdetails = data.data[0];
          setData({
            ...data,
            name: userdetails.name,
            media_id: userdetails.media_id,
            aboutYourself: userdetails.about_me,
            achievements: userdetails.achievement,
            facebookurl: userdetails.facebook,
            instagramurl: userdetails.instagram,
            linkedinurl: userdetails.linkdin,
            youtubeurl: userdetails.youtube,
            websiteurl: userdetails.website,
          });
          setLearntoteachonline(userdetails.online_learning);
          setInterest(userdetails.interest);
        }
        if (data.status === false) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };

  useEffect(() => {
    getuserdata();
    console.log("Check Id for Ask About", id);
  }, []);

  useEffect(() => {
    if (id) {
      getprofileapi();
    }
  }, []);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <AboutYourself data={data} handleChange={handleChange} />;
      case 1:
        return <AreaofInterest data={data} handleChange={handleChange} />;
      case 2:
        return <Achievements data={data} handleChange={handleChange} />;
      case 3:
        return (
          <UploadProfiles
            data={data}
            learntoteachonline={learntoteachonline}
            teachers={teacher}
            students={student}
            profilepic={profilepic}
            profilepicname={profilepicname}
            handleChange={handleChange}
            // handleBoolean={handleBoolean}
            handleDrop={handleDrop}
            // handleRadioBoolean={handleRadioBoolean}
          />
        );
      case 4:
        return (
          <Survey
            data={data}
            handleChange={handleChange}
            earntoteachonline={learntoteachonline}
            teachers={teacher}
            students={student}
            handleBoolean={handleBoolean}
            handleDrop={handleDrop}
            handleRadioBoolean={handleRadioBoolean}
          />
        );
      case 5:
        return <AddLinks data={data} handleChange={handleChange} />;
      default:
        return "Unknown stepIndex";
    }
  }

  const finishSteps = (details) => {
    // console.log(details);
  };

  useEffect(() => {
    setSuccess(false);
  }, []);

  return (
    <div className="askabout__container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="mt-4 text-center">Complete your profile</h4>
            <SteeperSection
              onsubmit={onsubmit}
              stepContent={getStepContent}
              finish={{
                finish: finishSteps,
              }}
              stepValue={activeStep}
            />
          </div>
        </div>
      </div>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
      {loading && (
        <LoaderContainer style={{ height: "60vh" }}>
          <FadeLoader
            color={Theme.LIGHT.INNERLOADER}
            loading={true}
            size={30}
          />
        </LoaderContainer>
      )}
      <Footer />
    </div>
  );
}

export default AskAbout;
