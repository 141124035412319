/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Explore from "./Explore/Explore";
import logo from "../../assets/Logo/skilllight.png";
import facebook from "../../assets/SocialMedia/facebook.png";
import google from "../../assets/SocialMedia/google.png";
import mail from "../../assets/SocialMedia/mail.png";
import notes from "../../assets/BackgroundImages/notes.jpeg";
import Fonts from "../../StyleConfig/Fonts";
import "./LandingPage.scss";
import Theme from "../../StyleConfig/Theme";
import HorizontalCard from "../../components/HorizontalCard/HorizontalCard";
import Footer from "../../components/Footer/Footer";
import SocialMediaAuth from "../../auth/helper/SocialMediaAuth";
import {
  facebookProvider,
  googleProvider,
} from "../../auth/helper/AuthMethods";
import { isAuthenticated, socialsignup } from "../../auth/helper";
import Loader from "../../components/Loader/Loader";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import { LoginTitle } from "../../StyleConfig/Common/CommonStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function LandingPage({ setLoading }) {
  const history = useHistory();
  const [landpage, setLandpage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loadings, setLoadings] = useState({
    googleLoading: false,
    facebookLoading: false,
  });
  const { googleLoading, facebookLoading } = loadings;
  /*
  firebase login
  */
  const handleOnClick = async (provider, loginType) => {
    // e.preventDefault();
    setWarning(false);
    setError(false);
    setSuccess(false);
    console.log("Check Provider", provider, "Login Type", loginType);
    const result = await SocialMediaAuth(provider);
    console.log("Check Social media result", result);
    // const user = firebase.auth().currentUser;
    // console.log("Check Sign Up user", user);
    if (result.code === "auth/popup-closed-by-user") {
      setResponseMessage("Unable to login");
      setWarning(true);
    }
    if (result.displayName && result.email !== undefined) {
      const displayName = result.displayName;
      const email = result.email;
      if (loginType == "Facebook") {
        setLoadings({ ...loadings, facebookLoading: true });
      } else {
        setLoadings({ ...loadings, googleLoading: true });
      }
      socialsignup({
        displayName,
        email,
        loginType,
      })
        .then((data) => {
          if (loginType == "Facebook") {
            setLoadings({ ...loadings, facebookLoading: false });
          } else {
            setLoadings({ ...loadings, googleLoading: false });
          }
          console.log("Check social sign up Response", data);
          if (data.status === true) {
            localStorage.setItem("token", JSON.stringify(data.data[0].token));
            localStorage.setItem(
              "userId",
              JSON.stringify(data.data[0].user_id)
            );
            localStorage.setItem("username", JSON.stringify(data.data[0].name));
            localStorage.setItem(
              "mediaId",
              JSON.stringify(data.data[0].media_id)
            );
            setResponseMessage("Log in Successful");
            setWarning(false);
            setError(false);
            setSuccess(true);
            if (data.data[0].Q6_status === 0) {
              setTimeout(() => {
                history.push("/askabout");
              }, 2000);
            } else {
              setTimeout(() => {
                if (history.location.state?.from.pathname) {
                  history.push(history.location.state?.from.pathname);
                } else {
                  history.push("/home");
                }
              }, 2000);
            }
          } else if (data.status === false) {
            setResponseMessage(data.msg);
            

            setSuccess(false);
            setWarning(false);
            setError(false);
          } else {
            setResponseMessage(data.msg);
            setSuccess(false);
            setWarning(false);
            setError(true);
          }
        })
        .catch((err) => {
          if (loginType == "Facebook") {
            setLoadings({ ...loadings, facebookLoading: false });
          } else {
            setLoadings({ ...loadings, googleLoading: false });
          }
          console.log(err);
        });
    } else {
      console.log("No data found");
    }
  };

  const checkLoggedIn = () => {
    if (isAuthenticated()) {
      history.push("/askabout");
      setLandpage(true);
    } else {
      setLandpage(true);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <>
      {landpage && (
        <div className="landingpage">
          <div className="landing__image">
            <div className="container">
              <div className="row">
                <div className="justify-between">
                  <img src={logo} alt="logo" className="app__logo" />
                  <Link
                    to={{
                      pathname: "/signin",
                    }}
                  >
                    <button className="btn btn-primary signin__button ">
                      Sign In
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="container signup__container">
              <div className="outline">
                <h4 className="text-light text-center">Getting Started</h4>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="justify-center">
                      <button
                        className="btn btn-outline-primary width100__per margin__20px signup__button"
                        type="button"
                        onClick={() =>
                          handleOnClick(facebookProvider, "Facebook")
                        }
                      >
                        <img
                          src={facebook}
                          alt="logo"
                          className="social__logo"
                        />
                        {facebookLoading ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <LoginTitle>Continue with Facebook</LoginTitle>
                        )}
                      </button>
                    </div>
                    <div className="justify-center">
                      <button
                        className="btn btn-outline-primary width100__per margin__20px signup__button"
                        type="button"
                        onClick={() => handleOnClick(googleProvider, "Gmail")}
                      >
                        <img src={google} alt="logo" className="social__logo" />
                        {googleLoading ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <LoginTitle loginTitle={Fonts} loginTheme={Theme}>
                            Continue with Google
                          </LoginTitle>
                        )}
                      </button>
                    </div>
                    <div className="justify-center">
                      <button
                        className="btn btn-outline-primary width100__per margin__20px signup__button"
                        type="button"
                        onClick={() => history.push("/signup")}
                      >
                        <img src={mail} alt="logo" className="social__logo" />
                        <LoginTitle loginTitle={Fonts} loginTheme={Theme}>
                          Continue with Email
                        </LoginTitle>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-center "
              onClick={() => {
                let explore = document.getElementById("section-explore");
                if (explore) {
                  explore.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <ExpandMoreIcon
                style={{
                  color: "white",
                  width: "50px",
                  height: "50px",
                  marginBottom: "10px",
                }}
                className="downarrow"
              />
            </div>
          </div>
          <div className="container">
            <Explore />
          </div>
          {/* <HorizontalCard imagePath={notes} /> */}
          <div style={{ height: "20px" }} />
          <Footer />
          {success && (
            <AppSnackbar message={responseMessage} severity="success" />
          )}
          {error && <AppSnackbar message={responseMessage} severity="error" />}
          {warning && (
            <AppSnackbar message={responseMessage} severity="warning" />
          )}
        </div>
      )}
    </>
  );
}

export default Loader(LandingPage);
