import firebase from "./firebase";

const SocialMediaAuth = (provider) => {
  console.log("Check Provider", provider);
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      console.log("Check Firebase Auth", res);
      return res.user;
    })
    .catch((error) => {
      return error;
    });
};

export default SocialMediaAuth;
