/**Auth API Calls */
import { API } from "../../backend";
import axios from "axios";

/**
 *
 *  User is SIGN-IN our SIGN-OUT
 */

export const isAuthenticated = () => {
  if (typeof window == undefined) {
    return false;
  }
  if (localStorage.getItem("token")) {
    return JSON.parse(localStorage.getItem("token"));
  } else {
    return false;
  }
};
/*
 * SOCIAL SIGNUP API
 */

export const socialsignup = (user) => {
  console.log("Check Social Signup data", user);
  var formData = new FormData();
  formData.append("name", user.displayName);
  formData.append("email", user.email);
  formData.append("login_type", user.loginType);

  return axios
    .post(API + "/social_signup", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/*
 * SIGNUP API
 */

export const signup = (user) => {
  console.log("Check Sign up user data", user);
  var formData = new FormData();
  formData.append("name", user.firstname + " " + user.lastname);
  formData.append("email", user.email);
  formData.append("mobile", user.mobilenumber);
  formData.append("password", user.password);

  return axios
    .post(API + "/signup", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("Check Sign up api response", res);

      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/*
 * SIGNIN API
 */

export const signin = (user) => {
  var formData = new FormData();
  formData.append("email", user.email);
  formData.append("password", user.password);

  return axios
    .post(API + "/signin", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("Check Login Api Response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 * FORGET PASSWORD API
 */

export const forgetpassword = (email) => {
  var formData = new FormData();
  formData.append("email", email);

  return axios
    .post(API + "/forgot_password", formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const resendemail = (email) => {
  var formData = new FormData();
  formData.append("email", email);
  return axios
    .post(API + "/resend_email", formData)
    .then((res) => {
      console.log("Check resend email response data", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check resend email response error", err);
      throw err;
    });
};
