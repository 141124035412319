import React, { useState, useEffect } from "react";
import "./SignUp.scss";
import { Link, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import Loader from "../../components/Loader/Loader";
import { signup } from "../../auth/helper";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import {
  EmailValidators,
  PasswordValidator,
  PhoneValidators,
} from "../../regex";
import {
  ContentBox,
  SaveButton,
  SaveButtonText,
} from "../../StyleConfig/Common/CommonStyles";
import AppModal from "../../components/AppModal/AppModal";

function SignUp({ setLoading }) {
  const history = useHistory();

  const [value, setValue] = useState();
  // USER SIGNUP DETAILS
  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobilenumber: "",
    loading: false,
    error: false,
    success: false,
    warning: false,
  });

  const [formerrors, setFormErrors] = useState({
    firstnamerror: true,
    lastnamerror: true,
    emailerror: true,
    passworderror: true,
    mobileerror: true,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [modalState, setModalState] = useState(false);

  const handleChange = (name) => (event) => {
    if (name === "firstname") {
      let checkfirstname = event.target.value;
      if (checkfirstname == "") {
        setFormErrors({ ...formerrors, firstnamerror: false });
      } else {
        setFormErrors({ ...formerrors, firstnamerror: true });
      }
    }
    if (name === "lastname") {
      let checklastname = event.target.value;
      if (checklastname == "") {
        setFormErrors({ ...formerrors, lastnamerror: false });
      } else {
        setFormErrors({ ...formerrors, lastnamerror: true });
      }
    }
    if (name === "email") {
      let checkemail = event.target.value;
      let isValidEmail = EmailValidators(checkemail);
      console.log("Check Is Valid", isValidEmail);
      setFormErrors({ ...formerrors, emailerror: isValidEmail });
    }
    if (name === "password") {
      let checkpassword = event.target.value;
      let isValidpassword = PasswordValidator(checkpassword);
      console.log("Check Is Valid", isValidpassword);
      setFormErrors({ ...formerrors, passworderror: isValidpassword });
    }
    if (name === "mobilenumber") {
      let checkmobile = event.target.value;
      let isValidMobile = PhoneValidators(checkmobile);
      console.log("Check Is Valid", isValidMobile);
      setFormErrors({ ...formerrors, mobileerror: isValidMobile });
    }
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onsubmit = (event) => {
    event.preventDefault();

    if (
      formerrors.firstnamerror === true &&
      formerrors.lastnamerror === true &&
      formerrors.emailerror === true &&
      formerrors.passworderror === true &&
      formerrors.mobileerror === true
    ) {
      let reqBody = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        mobilenumber: values.mobilenumber,
      };
      localStorage.setItem("email", JSON.stringify(values.email));
      setValues({
        ...values,
        error: false,
        warning: false,
        success: false,
        loading: true,
      });
      signup(reqBody)
        .then((data) => {
          console.log("Check Sign up api response", data);
          if (data.status === true) {
            setResponseMessage("Successfully Sign up");
            setValues({
              ...values,
              loading: false,
              error: false,
              warning: false,
              success: true,
              firstname: "",
              lastname: "",
              email: "",
              password: "",
              mobilenumber: "",
            });
            history.push("/verifyemail");
          } else if (data.status === false) {
            setResponseMessage(data.msg);
            setValues({
              ...values,
              loading: false,
              error: false,
              success: false,
              warning: true,
            });
          } else {
            setResponseMessage("Something went wrong");
            setValues({
              ...values,
              loading: false,
              success: false,
              warning: false,
              error: true,
              firstname: "",
              lastname: "",
              email: "",
              password: "",
              mobilenumber: "",
            });
          }
        })
        .catch((err) => {
          console.log("Check error for sign up", err);
          setResponseMessage("Something went wrong");
          setValues({
            ...values,
            loading: false,
            success: false,
            warning: false,
            error: true,
          });
        });
    } else {
      setResponseMessage("Please enter valid details to submit");
      setValues({
        ...values,
        loading: false,
        success: false,
        warning: false,
        error: true,
      });
    }
  };

  const handleClose = (name) => {
    if (name === "emailverify") {
      // setModalState(true);
    } else {
      return null;
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="signin__container">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-8 offset-sm-2 text-center"
            bis_skin_checked="1"
          >
            <ContentBox>
              <div className="content-box">
                <div
                  style={{
                    position: "absolute",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/")}
                >
                  <CancelIcon />
                </div>

                <div style={{ height: "15px" }} />
                <h5 className="text-center">Create Account</h5>
                <div style={{ height: "15px" }} />
                <div className="row">
                  <div className="col-md-10 offset-md-1 col-sm-10 offset-sm-1">
                    <div style={{ height: "15px" }} />
                    <form onSubmit={onsubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label" for="user_email">
                              First Name:<span className="asterisk">*</span>
                            </label>
                            <div style={{ height: "5px" }} />
                            <div className="control-input">
                              <input
                                placeholder="John"
                                className={`form-control ${
                                  formerrors.firstnamerror === false
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="user[name]"
                                id="user_name"
                                onChange={handleChange("firstname")}
                                type="text"
                                value={values.firstname}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label" for="user_email">
                              Last Name:<span className="asterisk">*</span>
                            </label>
                            <div style={{ height: "5px" }} />
                            <div className="control-input">
                              <input
                                placeholder="Doe"
                                className={`form-control ${
                                  formerrors.lastnamerror === false
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="user[lastname]"
                                id="user_lastname"
                                onChange={handleChange("lastname")}
                                type="text"
                                value={values.lastname}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ height: "15px" }} />
                      <div className="form-group">
                        <label className="control-label" for="user_email">
                          Email Address:<span className="asterisk">*</span>
                        </label>
                        <div style={{ height: "5px" }} />
                        <div className="control-input">
                          <input
                            //${values.isinvalid}
                            placeholder="user@gmail.com"
                            className={`form-control ${
                              formerrors.emailerror === false
                                ? "is-invalid"
                                : ""
                            }`}
                            name="user[email]"
                            id="user_email"
                            onChange={handleChange("email")}
                            type="email"
                            value={values.email}
                            required
                          />
                        </div>
                      </div>
                      <div style={{ height: "15px" }} />
                      <div className="form-group">
                        <label className="control-label" for="user_email">
                          Password:<span className="asterisk">*</span>
                        </label>
                        <div style={{ height: "5px" }} />


                        <div className="control-input">
                          <input
                            placeholder="Minimum 9 characters"
                            
                          
          
                            
                            className={`form-control ${
                              formerrors.passworderror === false
                                ? "is-invalid"
                                : ""
                            }`}
                            name="user[password]"
                            id="user_password"
                            type="password"
                            value={values.password}
                            required
                            
                            onChange={handleChange("password")}
                          />
                          </div>
                      </div>

                 
         

                      <div style={{ height: "15px" }} />
                      <div className="form-group">
                        <label className="control-label" for="user_email">
                          Mobile Number:<span className="asterisk">*</span>
                                  

                        </label>

                        <div style={{ height: "5px" }} />
                        <div className="control-input">
                        {/* <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={setValue}/> */}
                          <input
                             
                               
                              //  placeholder="Enter phone number"
                              //  value={value}
                              //  onChange={setValue}/>
                            maxLength={13}
                            placeholder="+91-999-999-9999"
                            className={`form-control ${
                              formerrors.mobileerror === false
                                ? "is-invalid"
                                : ""
                            }`}
                            name="user[mobile]"
                            id="user_mobile"
                            onChange={handleChange("mobilenumber")}
                            type="text"
                            value={values.mobilenumber}
                            required
                          />
                        </div>
                      </div>
                      <div style={{ height: "25px" }} />
                      <button
                        // onClick={onSubmit}
                        type={"submit"}
                        className="btn btn-block signupform__button"
                      >
                        {values.loading ? (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <span>Sign Up</span>
                        )}
                      </button>
                    </form>
                    <div style={{ height: "20px" }} />
                    <div className="flex-row justify-between">
                      <Link
                        to={{
                          pathname: "/signin",
                        }}
                      >
                        <span className="flex-end apptext__color">
                          Already have account?
                          <span style={{ color: "#1877f2", marginLeft: "2px" }}>
                            sign in
                          </span>
                        </span>
                      </Link>
                      <Link
                        to={{
                          pathname: "/forgetpassword",
                        }}
                      >
                        <span
                          className="flex-end apptext__color"
                          style={{ color: "#1877f2" }}
                        >
                          Forgot Password?
                        </span>
                      </Link>
                    </div>
                    <div style={{ height: "20px" }} />
                  </div>
                </div>
              </div>
            </ContentBox>
          </div>
        </div>
      </div>
      {/* {values.success && (
        <AppSnackbar message={responseMessage} severity="success" />
      )} */}
      {values.error && (
        <AppSnackbar message={responseMessage} severity="error" />
      )}
      {values.warning && (
        <AppSnackbar message={responseMessage} severity="warning" />
      )}
    </div>
  );
}

export default Loader(SignUp);
