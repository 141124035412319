import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import {
  AuthorName,
  AuthorProfileImage,
  FollowUnfollowButton,
  FollowUnfollowText,
} from "../../StyleConfig/Common/CommonStyles";
import { followunfollow, removefolloweruser } from "../../Core/coreapicalls";
import BeatLoader from "react-spinners/BeatLoader";

function FollowUnfollowList({
  data,
  role,
  handleClose,
  name,
  fetchFollowing,
  getProfile,
  listloading,
}) {
  console.log("Check Data", data, role);

  let { id } = useParams();
  let { pathname } = useLocation();

  const followunfollowapi = (status, id) => {
    console.log("Chcek Status", status, "Check id", id);
    let reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      follower_userid: id,
      status: status,
    };
    followunfollow(reqBody)
      .then((data) => {
        console.log("Check Fetch Following in FOLLOW / UNFOLLOW LIST", data);
        if (data.status === true) {
          getProfile();
          fetchFollowing(
            `${role == "Following" ? "fetch_following" : "fetch_follower"}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Remove Follower

  const removefolloweruserapi = (follower_userid) => {
    let reqBody = {
      api_token: JSON.parse(localStorage.getItem("token")),
      user_id: JSON.parse(localStorage.getItem("userId")),
      follower_userid: follower_userid,
    };
    removefolloweruser(reqBody)
      .then((data) => {
        console.log("Check REMOVED FOLLOWER", data);
        if (data.status === true) {
          getProfile();
          fetchFollowing(
            `${role == "Following" ? "fetch_following" : "fetch_follower"}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("Check Data", data);
  }, [data, listloading]);

  useEffect(() => {
    return () => {
      console.log("Follow / Unfollow Modal Close");
      handleClose(name);
    };
  }, [pathname]);

  return (
    <div className="pt-2" style={{ height: "50vh" }}>
      <div className="d-flex">
        <h4 className="text-center flex-grow-1" style={{ color: "#18A0FB" }}>
          {role}
        </h4>
        <div onClick={() => handleClose(name)}>
          <CancelIcon style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div style={{ overflow: "auto", height: "45vh" }}>
        {listloading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <BeatLoader color={"#FD3A66"} loading={true} size={20} />
          </div>
        )}
        {data.length !== 0 ? (
          data.map((list, index) => {
            console.log("Check list", list);
            return (
              <div key={"List" + index} className="row m-2 p-2">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <Link
                        to={{
                          pathname: `/profile/${parseInt(list.id)}`,
                        }}
                      >
                        <div className="d-flex">
                          <AuthorProfileImage
                            src={`${IMAGECONFIG.IMAGECONFIG.MEDIUM}${list.media_id}`}
                            alt={list.name}
                          />
                          <AuthorName style={{ marginLeft: "20px" }}>
                            {list.name}
                          </AuthorName>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-center">
                        {id ? (
                          list.id !=
                          JSON.parse(localStorage.getItem("userId")) ? (
                            <FollowUnfollowButton
                              // For Login User's End User Profile Page
                              onClick={() => {
                                if (list.follower_status == 1) {
                                  followunfollowapi("unfollow", list.id);
                                } else {
                                  followunfollowapi("follow", list.id);
                                }
                              }}
                            >
                              {list.follower_status == 1 ? (
                                <FollowUnfollowText>
                                  Unfollow
                                </FollowUnfollowText>
                              ) : (
                                <FollowUnfollowText>Follow</FollowUnfollowText>
                              )}
                            </FollowUnfollowButton>
                          ) : (
                            <></>
                          )
                        ) : (
                          <FollowUnfollowButton
                            // For Login User Profile Page
                            onClick={() => {
                              if (role === "Followers") {
                                removefolloweruserapi(list.id);
                              } else {
                                followunfollowapi("unfollow", list.id);
                              }
                            }}
                          >
                            {role === "Followers" ? (
                              <FollowUnfollowText>Remove</FollowUnfollowText>
                            ) : (
                              <FollowUnfollowText>Unfollow</FollowUnfollowText>
                            )}
                          </FollowUnfollowButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h4 style={{ color: "#FD3A66" }}>No Data Available</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default FollowUnfollowList;
