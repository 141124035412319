import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import CoursesCard from "../../components/CoursesCard/CoursesCard";
import Options from "../../components/Options";

import { useDataLayerValue } from "../../context/DataLayer";
import WatchHistory from "../../components/WatchHistory/WatchHistory";
import InnerLoader from "../../components/InnerLoader/InnerLoader";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddIcon from "@material-ui/icons/Add";
import Footer from "../../components/Footer/Footer";
import {
  getcoursedetails,
  getexplorecourses,
  getmycourses,
  ondeletecourse,
} from "../../Core/coreapicalls";
import Search from "../../components/Search/Search";
import "./MyCourses.scss";
import AppModal from "../../components/AppModal/AppModal";
import {
  CancelButton,
  LoaderContainer,
  SaveButton,
  SaveButtonText,
} from "../../StyleConfig/Common/CommonStyles";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import { FadeLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";
import { TrafficOutlined } from "@material-ui/icons";

const sections = [
  {
    id: 1,
    name: "Explore",
    path: "/courses/explore",
  },
  {
    id: 2,
    name: "My Courses",
    path: "/courses/mycourses",
  },
  {
    id: 3,
    name: "Watch History",
    path: "/courses/watchhistory",
  },
];

const MyCourses = (props) => {
  let location = useLocation();
  let { id } = useParams();
  const [modalState, setModalState] = useState({
    conformcoursedeletemodal: false,
    loader: false,
    error: false,
    success: false,
  });
  const [responseMessage, setResponseMessage] = useState("");

  const [{ coursesTabValue, mycourses, explorecourses, openModal }, dispatch] =
    useDataLayerValue();
  const [loading, setLoading] = useState(true);
  const [deleteCourseId, setDeleteCourseId] = useState(0);
  const [myCourse, setMyCourse] = useState([]);

  const getcourse = () => {
    console.log("Check Cat_ID", id);
    setLoading(true);
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      cat_id: id ? id : "",
    };
    getmycourses(reqBody)
      .then((data) => {
        setLoading(false);
        if (data.status === true) {
          console.log("Check Get My Course Data", data.data);
          setMyCourse(data.data);
          dispatch({
            mycourses: data.data,
            type: "SET__MYCOURSES",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleClose = (name) => {
    if (name == "deletecourse") {
      setModalState({ ...modalState, conformcoursedeletemodal: false });
    } else {
      return null;
    }
  };

  const getdeletecourseapi = () => {
    setModalState({ ...modalState, loader: true });
    let reqBody = {
      courses_id: deleteCourseId,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
    };
    ondeletecourse(reqBody)
      .then((res) => {
        console.log("Delete Course response in main file", res);
        setModalState({ ...modalState, loader: false });
        if (res.status === true) {
          setResponseMessage("Course deleted successfully!");
          setModalState({ ...modalState, error: false, success: true });
          getcourse();
          handleClose("deletecourse");
        }
      })
      .catch((error) => {
        setModalState({
          ...modalState,
          success: false,
          error: false,
          loader: false,
        });
        setResponseMessage("Something went wrong!");
        console.log("Delete Course error in main file", error);
        handleClose("deletecourse");
      });
  };

  const handleCourseDeletePopup = (id) => {
    console.log("Handle course Delete id", id);
    setDeleteCourseId(id);
    setModalState({ ...modalState, conformcoursedeletemodal: true });
  };

  useEffect(() => {
    getcourse();
    dispatch({
      coursesTabValue: 1,
      type: "SET__COURSESTAB__VALUE",
    });
  }, [id]);

  return (
    <div className="my_courses_container">
      <AppModal
        openModal={modalState.conformcoursedeletemodal}
        data={modalState}
        name="deletecourse"
        handleClose={handleClose}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Are you sure you want to delete this lecture ?</h5>
            </div>
          </div>
          <div style={{ height: "50px" }} />
          <div className="d-flex around">
            <CancelButton
              className="btn"
              onClick={() => handleClose("deletecourse")}
            >
              <SaveButtonText>Cancel</SaveButtonText>
            </CancelButton>
            <SaveButton className="btn" onClick={() => getdeletecourseapi()}>
              {modalState.loader ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <SaveButtonText>Confirm</SaveButtonText>
              )}
            </SaveButton>
          </div>
        </div>
      </AppModal>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ position: "relative" }}>
              <div>
                <Options value={sections} section={"MyCourse"} />
              </div>
              {loading === false && mycourses !== [] && (
                <>
                  <div className="flex-end">
                    <Link
                      to={{
                        pathname: "/courses/mycourses/createcourse",
                      }}
                    >
                      <button className="btn createcourse__button">
                        <span className="createcourse__text">
                          Create your own course
                        </span>
                        <AddIcon
                          style={{
                            color: "#fff",
                            marginLeft: "4px",
                          }}
                        />
                      </button>
                    </Link>
                  </div>
                  {loading === false && (
                    <div>
                      <CoursesCard
                        handleCourseDelete={handleCourseDeletePopup}
                        courses={myCourse}
                        location={location}
                        category={"Mycourses"}
                        routePath="mycourses"
                      />
                    </div>
                  )}
                </>
              )}
              {loading && (
                <LoaderContainer>
                  <FadeLoader
                    color={Theme.LIGHT.INNERLOADER}
                    loading={true}
                    size={50}
                  />
                </LoaderContainer>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "10px" }} />
      <Footer />
      {loading && InnerLoader()}
      {modalState.success && (
        <AppSnackbar message={responseMessage} severity="success" />
      )}
      {modalState.error && (
        <AppSnackbar message={responseMessage} severity="error" />
      )}
    </div>
  );
};

export default MyCourses;
