/**App Common Styles */

import React from "react";
import styled from "styled-components";
import Fonts from "../Fonts";
import Theme from "../Theme";

/*
 * #PARENT APP CONTAINER
 */
const AppContainer = styled.div`
  overflow: hidden;
  background-color: ${() => Theme.LIGHT.BG};
`;

/**
 * Author Name
 */

const AuthorName = styled.span`
  font-size: ${() => Fonts.SIZE.EIGHTEEN};
  font-weight: ${() => Fonts.WEIGHT.SIXHUNDRED};
  letter-spacing: 1px;
  color: #575758;
`;
const AuthorProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
`;
const Descriptions = styled.span`
  font-size: ${() => Fonts.SIZE.FOURTEEN};
  font-weight: ${() => Fonts.WEIGHT.FOURHUNDRED};
  letter-spacing: 0.2px;
  color: #575758;
`;
/**
 * Social Login Section
 */
const LoginTitle = styled.span`
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  color: #343440;
  position: absolute;
  left: 100px;
  top: 5px;
`;

/**
 * LOGIN SIGNUP CONTENT BOX
 *
 */

const ContentBox = styled.div`
  background: #fff;
  border-radius: 6px;
  padding-left: 0px;
  padding: 30px 45px;
  position: relative;
  text-align: left;
  width: 100%;
`;
const FacebookButton = styled.button`
  border-radius: 4px;
  background-color: #1877f2;
  max-width: 272px;
  min-width: initial;
  width: auto;
  border: none;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
`;
const FacebookTitle = styled.span`
  color: #fff;
  font-weight: 800;
  font-size: 14px;
  margin-left: 2px;
`;
const GoogleButton = styled.button`
  border-radius: 4px;
  background-color: #fff;
  max-width: 272px;
  min-width: initial;
  width: auto;
  border: 1px solid #4a4e6d;
  padding: 5px 5px 5px 5px;

  &:hover {
    box-shadow: 0 0 0 0.25rem rgba(33, 35, 56, 0.05%);
  }
`;

const GoogleTitle = styled.span`
  color: #4a4e6d;
  font-weight: 800;
  font-size: 14px;
  margin-left: 2px;
`;
/*
 * #CARDS
 */

const Cards = styled.div`
  margin-bottom: 10px;
  padding: 5px 5px 5px 5px;
  background-color: ${(props) => Theme.LIGHT.CARDBACKGROUND};
  box-shadow: ${(props) => Theme.LIGHT.CARDSHADOW};
  border-radius: 5px;
  width: 100%;
`;

/*
 * #COURSE TITLES
 */

const CourseSectionTitle = styled.h6`
  font-family: ${() => Fonts.FONTS.APPFONT};
  color: ${() => Theme.LIGHT.SECTIONTITLE};
  margin: 10px 10px 10px 10px;
`;

const CourseTitle = styled.span`
  font-family: ${() => Fonts.FONTS.APPFONT};
  color: ${() => Theme.LIGHT.COURSENAME};
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
  font-size: ${() => Fonts.SIZE.SIXTEEN};
`;

const CourseDescription = styled.span`
  font-family: ${() => Fonts.FONTS.APPFONT};
  color: ${() => Theme.LIGHT.COURSEDESCRIPTION};
  font-weight: ${() => Fonts.WEIGHT.FOURHUNDRED};
  font-size: ${() => Fonts.SIZE.FOURTEEN};
  margin: 5px 5px 5px 5px;
`;

const CourseDuration = styled.span`
  font-family: ${() => Fonts.FONTS.APPFONT};
  color: ${() => Theme.LIGHT.COURSEDURATION};
  font-weight: ${() => Fonts.WEIGHT.FOURHUNDRED};
  font-size: ${() => Fonts.SIZE.FOURTEEN};
`;

/**
 * #Courses Image
 */

const CourseGridImage = styled.img`
  width: 150px;
  height: 120px;
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
`;

const CourseGridTitle = styled.h5`
  margin: 10px 5px 5px 10px;
  font-family: ${() => Fonts.FONTS.APPFONT};
  color: rgb(60, 60, 60) 80%;
`;

const CourseGridSubtitle = styled.span`
  color: ${() => Theme.LIGHT.COURSEDESCRIPTION};
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-weight: 400;
  font-size: 14px;
  margin: 5px 5px 5px 5px;
  text-align: start;
`;
/*
 * #TAGS
 */

const TagsContainer = styled.div`
  margin: 5px 5px 5px 5px;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  background-color: ${() => Theme.LIGHT.TAGSCONTAINERBACKGROUND};
`;

const TagsTitle = styled.span`
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-size: ${() => Fonts.SIZE.TWELVE};
  text-align: center;
  color: #f1f6f9;
`;

/*
 * #BUTTONS
 */

const SaveButton = styled.button`
  background-color: #1ea69a;
  margin: 10px 10px 10px 10px;
  width: 130px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  width: 130px;
`;

const SaveButtonText = styled.span`
  color: #fff;
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-size: ${() => Fonts.SIZE.EIGHTEEN};
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
`;
const BackButton = styled.button`
  margin: 10px 10px 10px 10px;
  width: 130px;
  height: 45px;
  border: 2px solid #ff3a66;
  border-radius: 5px;
  cursor: pointer;
`;
const CancelButton = styled.button`
  margin: 10px 10px 10px 10px;
  padding: 10px;
  background-color: #ff3a66;
  border: none;
  width: 130px;
`;

const FollowUnfollowButton = styled.button`
  border-radius: 15px;
  border: 2px solid #1b98f5;
  background: transparent;
  width: 150px;
  &:hover {
    background-color: #1b98f5;
    transition: 1s;
  }
`;
const FollowUnfollowText = styled.span`
  color: #1b98f5;
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
  &:hover {
    color: #fff;
  }
`;
/*
 * #PROFILE SECTION
 */

const ProfilePageContainer = styled.div`
  background-color: ${() => Theme.LIGHT.BG};
  margin-top: 60px;
`;

const ProfileImageContainer = styled.div`
  margin-top: 30px;
  position: relative;
`;

const ProfileImage = styled.img`
  cursor: pointer;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin-left: 20px;
  &:hover {
    opacity: 0.5;
    transition: 1s;
  }
`;

/*
 * #FOOTER
 */

const AppFooter = styled.footer`
  // bottom: 0;
  // position: fixed;
  // width: 100%;
  height: 200px;
`;

/*
 * #VIDEO UPLOADING CONTAINER
 */

const VideoUploadContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(24, 160, 251, 0.28);
  display: flex;
  flex-direction: row;
`;

const VideoPercentageContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.background};
  width: 60px;
  zindex: 10;
  display: flex;
  justify-content: center;
`;

const UploadVideoPercentage = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 20px;
`;

/*
 * #Lecture cards
 */

const LectureCards = styled.div`
  margin: 8px 8px 8px 8px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  background-color: ${(props) => props.background};
`;

const LectureTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${() => Theme.LIGHT.COURSENAME};
  margin-left: 10px;
  font-family: Poppins;
`;

const ChipsCard = styled.span`
  background-color: rgba(199, 0, 57, 0.28);
  color: #c70039;
  padding: 10px;
  border-radius: 5px;
`;

/*
 * #Course Details Page
 */

const CourseHeaderTitle = styled.span`
  font-size: ${() => Fonts.SIZE.TWENTY};
  font-weight: ${() => Fonts.WEIGHT.SIXHUNDRED};
  letter-spacing: 1px;
`;

const CourseHeaderSubtitle = styled.span`
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  font-weight: ${() => Fonts.WEIGHT.FOURHUNDRED};
  letter-spacing: 0.5px;
`;
/**
 * #Form
 */
const FormTitle = styled.span`
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
  margin: 5px 5px 5px 5px;
  letter-spacing: 0.5px;
`;

/**
 * #Lectures
 */

const SessionCards = styled.div`
  background-color: ${(props) => props.background};
  border-radius: 5px;
`;
/**
 * #Loader
 */

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export {
  Cards,
  AppContainer,
  CourseSectionTitle,
  CourseTitle,
  CourseDescription,
  TagsContainer,
  TagsTitle,
  CourseDuration,
  SaveButton,
  SaveButtonText,
  CancelButton,
  CourseGridImage,
  CourseGridTitle,
  CourseGridSubtitle,
  ProfilePageContainer,
  ProfileImageContainer,
  ProfileImage,
  AppFooter,
  VideoUploadContainer,
  VideoPercentageContainer,
  UploadVideoPercentage,
  LectureCards,
  SessionCards,
  LectureTitle,
  ChipsCard,
  CourseHeaderTitle,
  CourseHeaderSubtitle,
  FormTitle,
  LoginTitle,
  BackButton,
  ContentBox,
  FacebookButton,
  FacebookTitle,
  GoogleButton,
  GoogleTitle,
  AuthorName,
  AuthorProfileImage,
  Descriptions,
  FollowUnfollowButton,
  FollowUnfollowText,
  LoaderContainer,
};
