import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Fonts from "../StyleConfig/Fonts";
import { useDataLayerValue } from "../context/DataLayer";

function Options({ value, section }) {
  const [{ coursesTabValue, segmentTabValue }, dispatch] = useDataLayerValue();
  const [tabValue, setTabValue] = useState(1);

  return (
    <div className="flex-row space-even">
      {value.map((option, index) => {
        return (
          <div key={index + "option"}>
            <OptionButton>
              <NavLink
                activeStyle={{
                  color: "#1877f2",
                  transition: "1s",
                }}
                to={{
                  pathname: option.path,
                }}
              >
                <OptionName
                  key={(item) => item.id}
                  fonts={Fonts}
                  tab={tabValue}
                  id={option.id}
                >
                  {option.name}
                </OptionName>
              </NavLink>
            </OptionButton>
          </div>
        );
      })}
    </div>
  );
}

export default Options;

const OptionName = styled.h5`
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-weight: ${() => Fonts.WEIGHT.SIXHUNDRED};
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  }};
  border-bottom: ${(props) => {
    if (props.tab == props.id) {
      return "5px solid";
    } else {
      return "none";
    }
  }}
  border-bottom-color: ${(props) => {
    if (props.tab == props.id) {
      return "#18a0fb";
    } else {
      return "none";
    }
  }}
`;

const OptionNames = styled.h6`
  font-family: ${() => Fonts.FONTS.APPFONT};
  font-weight: ${() => Fonts.WEIGHT.FIVEHUNDRED};
  font-size: ${() => Fonts.SIZE.SIXTEEN};
  color: ${(props) => props.color}
  }};
`;

const OptionButton = styled.button`
  padding: 10px;
  border: none;
  background-color: transparent;
`;

export const SegmentControl = ({ data, segmentFor, getCourses }) => {
  const [{ segmentTabValue, segmentTabValueForCourse }, dispatch] =
    useDataLayerValue();

  return (
    <div className="d-flex space-even">
      {data.map((option, index) => {
        let newIndex = index + 1;
        return (
          <div key={option.name}>
            <OptionButton
              onClick={() => {
                console.log("Check Segment for", segmentFor);
                if (segmentFor === "Explore") {
                  getCourses(option.apicategory);

                  dispatch({
                    segmentTabValueForCourse: newIndex,
                    type: "SET__SEGMENTTABFORCOURSE__VALUE",
                  });
                } else {
                  dispatch({
                    segmentTabValue: newIndex,
                    type: "SET__SEGMENTTAB__VALUE",
                  });
                }
              }}
            >
              {segmentFor === "Explore" ? (
                <OptionNames
                  color={
                    segmentTabValueForCourse == newIndex ? "#1877F2" : "#575758"
                  }
                >
                  {option.name}
                </OptionNames>
              ) : (
                <OptionNames
                  color={segmentTabValue == newIndex ? "#1877F2" : "#575758"}
                >
                  {option.name}
                </OptionNames>
              )}
            </OptionButton>
          </div>
        );
      })}
    </div>
  );
};
