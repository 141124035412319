import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import AppSnackbar from "../../../components/AppSnackbar/AppSnackbar";
import VideoPlayer from "../../../components/Video/VideoPlayer";
import { SegmentControl } from "../../../components/Options";
import { useDataLayerValue } from "../../../context/DataLayer";
// import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import '../CourseDetails/coursebtn.css';


import Footer from "../../../components/Footer/Footer";
import {
  addcoursereview,
  addwatchlist,
  getcoursedetails,
  getcoursereview,
} from "../../../Core/coreapicalls";
import {
  CourseHeaderTitle,
  CourseHeaderSubtitle,
  SessionCards,
  SaveButton,
  SaveButtonText,
  CourseDuration,
  AuthorName,
  Descriptions,
} from "../../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../../StyleConfig/MediaImage";
import Ratings, { DisplayRatings } from "../../../components/Ratings/Ratings";
import "./CourseDetail.scss";

const sections = [
  {
    id: 1,
    name: "About Course",
  },
  {
    id: 2,
    name: "Reviews",
  },
];

function CourseDetail(props) {
  let { id } = useParams();
  const [{ coursesTabValue, segmentTabValue, videopath }, dispatch] =
    useDataLayerValue();
  // const courseDetail = props.location.state;
  const [coursedetails, setCoursedetails] = useState({
    coursedata: {},
    success: false,
    error: false,
  });

  const { coursedata, success, error } = coursedetails;

  const [coursesession, setCoursesession] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  const [getCourseReview, setGetCourseReview] = useState([]);
  const [addReview, setAddReview] = useState({
    coursereview: "",
    courserating: 0,
    loading: false,
  });

  const handleRatings = (value) => {
    setAddReview({ ...addReview, courserating: value });
  };

  const getcoursedetailapi = () => {
    let reqBody = {
      id: id,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
    };

    getcoursedetails(reqBody)
      .then((res) => {
        console.log("Check Course Details", res);
        if (res.status === true) {
          setCoursesession(res.sections.reverse());
          setCoursedetails({
            ...coursedetails,
            coursedata: res.data,
            success: true,
            error: false,
          });
          dispatch({
            videopath: res.sections[0],
            type: "SET__VIDEO__PATH",
          });
        }
        if (res.status === false) {
          setResponseMessage("No Records Found.");
          setCoursedetails({ ...coursedetails, success: false, error: true });
        }
      })
      .catch((error) => {
        setResponseMessage("Something went wrong.");
        setCoursedetails({ ...coursedetails, success: false, error: true });
      });
  };

  const getcoursereviewapi = () => {
    let reqBody = {
      id: id,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
    };
    getcoursereview(reqBody)
      .then((res) => {
        console.log("Check Response for course review", res);
        if (res.status === true) {
          setGetCourseReview(res.data);
        }
      })
      .catch((error) => console.log("Check Review Api error", error));
  };

  const addcoursereviewapi = () => {
    setAddReview({ ...addReview, loading: true });
    let reqBody = {
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
      media_id: JSON.parse(localStorage.getItem("mediaId")),
      review_from: JSON.parse(localStorage.getItem("userId")),
      review_from_name: JSON.parse(localStorage.getItem("username")),
      review_to: coursedata.author_id,
      courses_id: id,
      review: addReview.coursereview,
      rating: addReview.courserating,
    };
    addcoursereview(reqBody)
      .then((res) => {
        setAddReview({ ...addReview, loading: false });

        console.log("Check Response for add course review", res);
        if (res.status === true) {
          setAddReview({ ...addReview, coursereview: "", courserating: "" });
          getcoursereviewapi();
        }
        else if(res.status === false){
          setResponseMessage(res.msg);
          setCoursedetails({ ...coursedetails, success: false, error: true });
          
        }
        
      })
      .catch((error) => {
        setAddReview({ ...addReview, loading: false });

        console.log("Check add Review Api error", error);
      });
  };

  const addwatchlistapi = (lectureId) => {
    let reqBody = {
      courses_id: id,
      section_id: lectureId,
      api_token: JSON.parse(localStorage.getItem("token")),
      user_id: JSON.parse(localStorage.getItem("userId")),
    };
    addwatchlist(reqBody)
      .then((res) => console.log("Add watch list api response", res))
      .catch((err) => console.log("Add watch list api error", err));
  };

  useEffect(() => {
    getcoursedetailapi();
    getcoursereviewapi();
  }, []);

  useEffect(() => {
    console.log("Check Segment Tab Value", segmentTabValue);
    dispatch({
      videopath: "",
      type: "SET__VIDEO__PATH",
    });
  }, []);
  const { push } = useHistory()

  return (

    <div>



      <div className="container-fluid">



        <div className="row">
          
        

          <div className="col-md-12 course__detail__container">
            <CourseHeaderTitle>{coursedata.name}</CourseHeaderTitle>
            <div style={{ height: "5px" }} />
            <CourseHeaderSubtitle>{coursedata.info}</CourseHeaderSubtitle>

            
          <div className="d-flex justify-content-end">
            <button

              type="button"
              className="button2 "
              onClick={() => push('/courses/explore')}
            >
              Back
            </button>
          </div>
          </div>
          {/* add new button */}
{/* 
          <div className="d-flex justify-content-end">
            <button

              type="button"
              className="button2 "
              onClick={() => push('/courses/explore')}
            >
              My Course
            </button>
          </div> */}


          {/* add new button */}

          <div className="col-md-8">
            <VideoPlayer
              courseDetails={coursedetails}
              courseLectures={coursesession}
            />
            <div style={{ height: "10px" }} />
            <SegmentControl data={sections} segmentFor="CourseDetail" />

            <div style={{ height: "10px" }} />
            {segmentTabValue === 1 && (
              <div className="course__detail__review__container">
                <div>
                  <img
                    src={`${IMAGECONFIG.IMAGECONFIG.BANNER +
                      coursedetails.coursedata.author_mediaid
                      }`}
                    alt=""
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "100%",
                    }}
                  />
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "#012432",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {coursedetails.coursedata.author_name}
                  </span>
                  <br />
                  <div style={{ height: "10px" }} />
                  <Descriptions>{coursedetails.coursedata.info}</Descriptions>
                </div>
              </div>
            )}
            {segmentTabValue === 2 && (
              <div
                style={{
                  backgroundColor: "#F6F8FA",
                  height: "500px",
                  position: "relative",
                }}
              >
                {getCourseReview.length > 0 ? (
                  <div
                    style={{
                      overflow: "auto",
                      height: "350px",
                    }}
                  >
                    {getCourseReview.map((reviewData, index) => {
                      console.log("Check Review Data", reviewData);
                      return (
                        <div key={"Review" + index} style={{ padding: "10px" }}>
                          <div>
                            <img
                              src={
                                IMAGECONFIG.IMAGECONFIG.SMALL +
                                reviewData.media_id
                              }
                              alt={reviewData.review_from_name}
                              style={{ borderRadius: "100%" }}
                            />
                          </div>

                          <div className="mt-2">
                            <TimelapseIcon
                              style={{
                                color: "#FD7F44",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                            <CourseDuration className="ms-2">
                              {moment(reviewData.created_at).fromNow()}
                            </CourseDuration>
                          </div>
                          <div className="mt-2">
                            <DisplayRatings rating={reviewData.rating} />
                          </div>
                          <div>
                            <AuthorName>
                              {reviewData.review_from_name}
                            </AuthorName>
                          </div>
                          <div>
                            <Descriptions>{reviewData.review}</Descriptions>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                    }}
                  >
                    <h4 style={{ color: "#4267B2" }}>No reviews yet.</h4>
                  </div>
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    width: "100%",
                  }}
                >
                  <Ratings data={addReview} handleChange={handleRatings} />
                  <div className="d-flex">
                    <div className="form-group flex-grow-1">
                      <input
                        value={addReview.coursereview}
                        onChange={(e) =>
                          setAddReview({
                            ...addReview,
                            // ...addReview,
                            coursereview: e.target.value,
                          })
                        }
                        className="form-control"
                        placeholder="Add Review"
                        style={{
                          width: "100%",
                          margin: "10px 0px 0px 0px",
                        }}
                      />
                    </div>
                    <SaveButton className="btn" onClick={addcoursereviewapi}>
                      {addReview.loading ? (
                        <div class="spinner-grow text-warning" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <SaveButtonText>Post</SaveButtonText>
                      )}
                    </SaveButton>
                  </div>
                </div>
              </div>
            )}
            <div style={{ height: "10px" }} />
            <div style={{ height: "10px" }} />
          </div>
          <div className="col-md-4">
            <div className="lectures__container">
              {coursesession !== null ? (
                <div className="d-flex flex-column">
                  {coursesession.map((lec, index) => {
                    // console.log("Check Lectures", lec);
                    let newIndex = index + 1;
                    return (
                      <>
                        <SessionCards
                          background={
                            newIndex % 2 === 0
                              ? lec.id === videopath.id
                                ? "#B2D6FA"
                                : "#f4f7fa"
                              : lec.id === videopath.id
                                ? "#FFBFA0"
                                : "#FEF5F3"
                          }
                          onClick={() => {
                            dispatch({
                              videopath: lec,
                              type: "SET__VIDEO__PATH",
                            });
                            addwatchlistapi(lec.id);
                          }}
                        >
                          <div className="flex-row">
                            <div
                              className={
                                newIndex % 2 == 0
                                  ? "number__container__green"
                                  : "number__container__orange"
                              }
                            >
                              <span>{"0" + newIndex}</span>
                            </div>
                            <div className="flex-column padding__left__10px ">
                              <div style={{ height: "5px" }} />
                              <h5>{lec.title}</h5>
                              <span>{lec.duration}</span>
                            </div>
                          </div>
                        </SessionCards>
                        {/* </div> */}
                        <div style={{ height: "20px" }} />
                      </>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <h5 className="text-center">No Lectures</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "10px" }} />
      <Footer />
      {error && <AppSnackbar message={responseMessage} severity="error" />}
    </div>
  );
}

export default CourseDetail;
